import { useEffect, useState } from 'react';

import Cookies from 'universal-cookie';

import { PageHeader } from '../header/PageHeader';
import { CompCreate } from './CompCreate';
import { ProgressSpinner } from 'primereact/progressspinner';

import styles from './CompHome.css';
import axios from 'axios';

function CompHome() {
    const cookies = new Cookies();
    const [companyData, setCompanyData] = useState(null);
    const [step, setStep] = useState('loading');

    const getCompanyData = () => {
        const headers = {
            'Authorization': 'Bearer ' + cookies.get('token')
        }

        axios.get(
            '/company/',
            {
                headers: headers
            }
        ).then(
            response => {
                setTimeout(
                    () => {
                        setCompanyData(response);
                        setStep('data-ok')
                    },
                    1000
                );
            }
        ).catch(
            error => {
                if (error.response.status == 404) {
                    setTimeout(
                        () => {
                            setStep('create-new-company');
                        },
                        1000
                    );
                }
            }
        )
    }

    useEffect(
        () => {
            getCompanyData();
        },
        []
    );

    return (
        <div className={styles.myyolo}>
            <PageHeader mode='company' />
            {
                step == 'loading' ?
                    <div className='comp-home-loading-data'>
                        <table align='center'>
                            <tr>
                                <td>
                                    <ProgressSpinner />
                                </td>
                                <td>
                                    Loading company data...
                                </td>
                            </tr>
                        </table>
                    </div>
                    : null
            }
            {
                step == 'create-new-company' ?
                    <div>
                        <CompCreate />
                    </div>
                    : null
            }
        </div>
    );
}

export default CompHome;