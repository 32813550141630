import { useEffect, useState } from "react";
import { getProject } from "../../services/ServiceProject.ts";
import V3ProjectDevservers from "../project_devservers/V3ProjectDevservers";
import V3ProjectMembers from "../project_members/V3ProjectMembers";
import V3ProjectMenu from "../project_menu/V3ProjectMenu";
import V3ProjectSettings from "../project_settings/V3ProjectSettings";
import V3ProjectSummary from "../project_summary/V3ProjectSummary";
import V3ProjectTemplates from "../project_templates/V3ProjectTemplates";

function V3Project({ projectID, loadProjects }) {
    const [project, setProject] = useState(null);
    const [selectedItem, setSelectedItem] = useState('summary');

    useEffect(
        () => {
            getProject(projectID).then(
                (response) => setProject(response.data),
            );
        },
        [projectID],
    );

    if (project == null) {
        return (
            <div>
                Loading..
            </div>
        );
    }

    return (
        <div>
            <div className='v3-feed-main-item-center'>
                <V3ProjectMenu
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                />
            </div>
            {
                selectedItem == 'summary'
                    ? <div className="v3-feed-main-item-center">
                        <V3ProjectSummary project={project} />
                    </div>
                    : null
            }
            {
                selectedItem == 'templates'
                    ? <V3ProjectTemplates
                        projectID={projectID}
                        goToDevserversPage={() => setSelectedItem('devservers')}
                    />
                    : null
            }
            {
                selectedItem == 'devservers'
                    ? <V3ProjectDevservers projectID={projectID} />
                    : null
            }
            {
                selectedItem == 'members'
                    ? <V3ProjectMembers
                        projectID={projectID}
                    />
                    : null
            }
            {
                selectedItem == 'settings'
                    ? <V3ProjectSettings
                        projectID={projectID}
                        loadProjects={loadProjects}
                    />
                    : null
            }
        </div>
    );
}

export default V3Project;