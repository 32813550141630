import './PageDevserverCreate.css';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { useState } from 'react';

import Cookies from 'universal-cookie';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Password } from 'primereact/password';
import { RadioButton } from 'primereact/radiobutton';
import { TabView, TabPanel } from 'primereact/tabview';

import { PageHeader } from '../../header/PageHeader';

function PageDevserverCreate({ gkDjango }) {
    const [serverName, setServerName] = useState(null);
    const [serverSubdomain, setServerSubdomain] = useState(null);
    const [showDialog, setShowDialog] = useState(null);
    const [showDialogError, setShowDialogError] = useState(null);
    const [serverPassword, setServerPassword] = useState(null);
    const [serverCertificate, setServerCertificate] = useState('dev');
    const [services, setServices] = useState('default-set1');
    const [validationError, setValidationError] = useState();

    const [activeIndex, setActiveIndex] = useState(0);

    const cookies = new Cookies();

    const onlyLettersAndNumbers = (str) => {
        return /^[A-Za-z0-9]*$/.test(str);
    }

    const createDevserver = () => {
        if (onlyLettersAndNumbers(serverName) == false) {
            setValidationError('Devserver name must be letters and numbers only.');
            setShowDialogError(true);
            return;
        }
        if (serverName == null || serverName == '' || serverPassword == null || serverPassword == '') {
            setValidationError('Devserver name and/or password cannot be empty.')
            setShowDialogError(true);
            return;
        }

        setShowDialog(true);

        const data = {
            name: serverName,
            password: serverPassword,
            default_services: services,
            certificate_type: serverCertificate
        };
        const headers = {
            'Authorization': 'Bearer ' + cookies.get('token')
        }
        axios.post(
            '/devserver/',
            data,
            {
                headers: headers
            }
        ).then(
            response => {
                setShowDialog(false);
                navigate('/home');
            }
        ).catch(
            _error => {
                setShowDialog(false);
            }
        )
    }

    const [region, setRegion] = useState(null);

    const regions = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];

    const cpuOptions = [
        { name: '1 core' },
        { name: '2 cores' },
        { name: '4 cores' },
        { name: '8 cores' },
    ];

    const onRegionChange = (e) => {
        setRegion(e.value);
    }

    const navigate = useNavigate();

    return (
        <div>
            <PageHeader mode='account' />
            <div className='dc-main-container'>
                <div className='dc-main-div'>
                    <div className='dc-title'>
                        Create here a new devserver
                    </div>
                    <div class='dc-main-table'>
                        <div className='dc-item-title'>
                            Machine
                        </div>
                        <div className='dc-item'>
                            <InputText value={serverName}
                                onChange={(e) => setServerName(e.target.value)}
                                placeholder="name, eg. mydev123"
                                autoComplete='new-password' />
                        </div>
                    </div>
                    <div className='dc-item'>
                        <div align='center'>
                            <div style={{ width: '512px' }}>
                                <div className='dc-item-title'>
                                    Authentication
                                </div>
                                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                                    <TabPanel header="Password">
                                        <div>
                                            <Password value={serverPassword}
                                                onChange={(e) => setServerPassword(e.target.value)}
                                                placeholder="set root password"
                                                autoComplete='new-password' />
                                        </div>
                                    </TabPanel>
                                    <TabPanel header="Public Key" disabled={true}>
                                        <div style={{ color: 'red' }}>
                                            Authentication based on Public Key is currently unavailable.
                                        </div>
                                    </TabPanel>
                                </TabView>
                            </div>
                        </div>
                    </div>
                    <div className='dc-item'>
                        <div className='dc-item-title'>
                            Default Services
                        </div>
                        <table align='center'>
                            <tr>
                                <td>
                                    <div className='services-item'>
                                        <RadioButton value="default-set1" name="city" onChange={(e) => setServices(e.value)} checked={services === 'default-set1'} />
                                        <label className='service-label' htmlFor="city2">React</label>
                                    </div>
                                </td>
                                <td>
                                    <div className='services-item'>
                                        <RadioButton value="default-set2" name="city" onChange={(e) => setServices(e.value)} checked={services === 'default-set2'} />
                                        <label className='service-label' htmlFor="city2">Angular</label>
                                    </div>
                                </td>
                                {
                                    gkDjango == true ?
                                        <td>
                                            <div className='services-item'>
                                                <RadioButton value="default-set3" name="city" onChange={(e) => setServices(e.value)} checked={services === 'default-set3'} />
                                                <label className='service-label' htmlFor="city2">React + Django + MySQL</label>
                                            </div>
                                        </td>
                                        : null
                                }
                                <td>
                                    <div className='services-item'>
                                        <RadioButton value="default-none" name="city" onChange={(e) => setServices(e.value)} checked={services === 'default-none'} />
                                        <label className='service-label' htmlFor="city2">None</label>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className='dc-item'>
                        <div className='dc-item-title'>
                            SSL Certificates
                        </div>
                        <table align='center'>
                            <tr>
                                <td>
                                    <div className='services-item'>
                                        <RadioButton value="ca-signed" name="r1" onChange={(e) => setServerCertificate(e.value)} checked={serverCertificate === 'ca-signed'} />
                                        <label className='service-label' htmlFor="r1">CA Signed</label>
                                    </div>
                                </td>
                                <td>
                                    <div className='services-item'>
                                        <RadioButton value="dev" name="r2" onChange={(e) => setServerCertificate(e.value)} checked={serverCertificate === 'dev'} />
                                        <label className='service-label' htmlFor="r2">Development Certificates (default)</label>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div className='dc-item-button'>
                        <Button label="Create Devserver" className="p-button-success" onClick={createDevserver} />
                    </div>
                    <Dialog header="Unable to create a new devserver&nbsp;" visible={showDialogError} onHide={() => setShowDialogError(false)}>
                        <div className='dc-dialog'>
                            <div className='dc-create-error'>
                                <div>
                                    {validationError}
                                </div>
                            </div>
                        </div>
                    </Dialog>
                    <Dialog header="Sign Up" visible={showDialog} onHide={() => setShowDialog(false)}>
                        <div className='dc-dialog'>
                            <div>
                                Creating your devserver...
                            </div>
                            <div>
                                <ProgressSpinner />
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        </div >
    );
}

export default PageDevserverCreate;