import './SignUpByInvite.css';

import { useState } from 'react';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useNavigate } from 'react-router-dom';

function SignUpByInvite() {

    const navigate = useNavigate();
    const [step, setStep] = useState('init');

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const invite_id = urlParams.get('id');

    const createAccount = () => {
        setStep('creating-account');
        setTimeout(
            () => {
                setStep('done');
            },
            5000
        );
    }

    return (
        <div>
            {step === "init" ?
                <div className="si" align='center'>
                    <h2>
                        Account Creation
                    </h2>
                    <p>
                        Invite ID: {invite_id}
                    </p>
                    <p>
                        This account will be fully managed by the company that invited you to sign up.
                    </p>
                    <div className='login-input'>
                        <InputText value={null} onChange={() => { }} placeholder="email" />
                    </div>
                    <div className='login-input'>
                        <Password value={null} onChange={() => { }} placeholder="password" />
                    </div>
                    <div>
                        <Button label="Sign Up" className="p-button-success" onClick={createAccount} />
                    </div>
                </div>
                : null
            }
            {
                step === 'creating-account' ?
                    <div className="si" align='center'>
                        <h2>
                            Account Creation
                        </h2>
                        <div>
                            Your acount is being created...
                            <div className='div-spinner'>
                                <ProgressSpinner />
                            </div>
                        </div>
                    </div>
                    : null
            }
            {step === 'success' ?
                <div>
                    <div classname='div-success-content'>
                        Your account was successfully created!
                    </div>
                    <div className='div-success-button'>
                        <Button label="Ok" onClick={() => { navigate('/'); }} />
                    </div>
                </div>
                : null
            }
        </div>
    );
}

export default SignUpByInvite;