import MarketinMenu from '../v2/MarketingMenu';
import './Demo.css';

import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import { logEvent } from './../Logger';

function Demo() {

    const navigate = useNavigate();
    const [step, setStep] = useState('wait-to-start');
    // const [step, setStep] = useState('s1-choose-stack');
    // const [step, setStep] = useState('details');
    // const [step, setStep] = useState('review');

    const [stack, setStack] = useState([]);
    const [shouldSeeMore, setShouldSeeMore] = useState(false);
    const [isCloned, setIsCloned] = useState(false);

    const [techs, setTechs] = useState(
        [
            'React',
            'Angular',
            'Vue',
            'Django',
            'Laravel',
            'MySql',
            'PosgreSql',
            'MongoDB',
        ]
    );
    const [customService, setCustomService] = useState(null);
    const availableWikis = {
        'React': 'https://reactjs.org/docs/create-a-new-react-app.html',
        'Angular': 'https://angular.io/tutorial/toh-pt0',
        'Vue': 'https://cli.vuejs.org/guide/creating-a-project.html',
        'Django': 'https://docs.djangoproject.com/en/4.1/intro/tutorial01/',
        'Laravel': 'https://laravel.com/docs/9.x/installation',
        'MySql': 'https://dev.mysql.com/doc/mysql-installation-excerpt/5.7/en/',
        'PosgreSql': 'https://www.postgresql.org/docs/current/tutorial-install.html',
        'MongoDB': 'https://www.mongodb.com/docs/manual/installation/',
    };
    const [wikis, setWikis] = useState([]);

    const calculateWikis = () => {
        const new_wikis = stack.map(
            item => availableWikis[item]
        );
        setWikis(new_wikis);
    };

    const stepWaitToStart =
        <div className='demo-step-wait-to-start'>
            <div className='demo-step-wait-to-start-content'>
                <h2>
                    This demo will setup some ephemeral scenarios.
                </h2>
                <h3>
                    No need to download anything.
                </h3>
            </div>
            <div
                className='demo-start-button'
                onClick={
                    () => {

                        logEvent('click_create_demo_account');
                        setStep('ack-role');
                    }
                }
            >
                Start the demo
            </div>
        </div>;

    const stepAckRole =
        <div className='demo-step-ack-role'>
            <h2>
                Pick one of these scenarios...
            </h2>
            <div>
                <table>
                    <tr>
                        <td>
                            <div
                                className='demo-step-ack-role-scenario'
                                align='center'
                                onClick={
                                    () => {
                                        setStep('s1-choose-stack');
                                    }
                                }
                            >
                                Setup a new project
                            </div>
                        </td>
                        <td>
                            <div
                                className='demo-step-ack-role-scenario'
                                align='center'
                                onClick={
                                    () => {
                                        setStep('s1-choose-stack');
                                    }
                                }
                            >
                                Import an existing project
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>;

    const removeTech = (tech_to_be_removed) => {
        const new_tech = techs.filter(
            item => item != tech_to_be_removed
        );
        setTechs(new_tech);
    };
    const techsDivs = techs.map(
        tech =>
            <span
                className='demo-step-choose-stack-tech'
                onClick={
                    () => {
                        setStack([...stack, tech])
                        removeTech(tech)
                    }
                }
            >
                {tech}
            </span>
    );

    const removeStack = (item_to_be_removed) => {
        const new_stack = stack.filter(
            item => item != item_to_be_removed
        );
        setStack(new_stack);
    };
    const stackDivs = stack.map(
        item =>
            <span
                className='demo-step-choose-stack-tech'
                onClick={
                    () => {
                        removeStack(item);
                        setTechs([...techs, item]);
                    }
                }
            >
                {item}
            </span>
    );

    const stepS1ChooseStack = <div className='s1-choose-stack'>
        <h1>
            Choose your favorite technical stack...
        </h1>
        <h3>
            Remember, this is just a hypothetical demo project, nothing
            will be created or committed.
        </h3>
        <div>
            <div className='demo-step-choose-stack-collumn'>
                <h3>
                    Your project:
                </h3>
                {stackDivs}
            </div>
            <div className='demo-step-choose-stack-collumn'>
                <h3>
                    Some suggestions:
                </h3>
                {techsDivs}
                <p style={{ padding: '0.4em', color: 'grey' }}>
                    click to add on your project
                </p>
            </div>

            <div className='demo-step-choose-stack-collumn'>
                <h3>
                    Or add your own custom service:
                </h3>
                <table>
                    <tr>
                        <td>
                            <InputText
                                placeholder='any text would work'
                                value={customService}
                                onChange={
                                    (e) => setCustomService(e.target.value)
                                }
                            />
                        </td>
                        <td>
                            <Button
                                label='Add'
                                disabled={customService == null || customService == ''}
                                className="p-button-success"
                                onClick={
                                    () => {
                                        setStack([...stack, customService]);
                                        setCustomService('');
                                    }
                                }
                            />
                        </td>
                    </tr>
                </table>
            </div>
            <table>
                <tr>
                    <td>
                        <Button
                            label='Reset'
                            disabled={stack.length == 0}
                            onClick={
                                () => { setStack([]); }
                            }
                        />
                    </td>
                    <td>
                        <Button
                            label='Contine'
                            disabled={stack.length == 0}
                            onClick={
                                () => {
                                    calculateWikis();
                                    setStep('review');
                                }
                            }
                        />
                    </td>
                </tr>
            </table>
        </div>
    </div>;

    const wikisDivs = wikis.map(
        item => <div>
            {item}
        </div>
    );
    const stepS1Review =
        <div className='review' align='center'>
            <h3>
                So... we want to start a new project with the following stack:
            </h3>
            <div>
                {stackDivs}
            </div>
            <table>
                <tr>
                    <td align='center' width='50%'>
                        <div className='demo-step-review-todos'>
                            <h3>
                                We will need to consult the following wikis:
                            </h3>
                            <div>
                                {wikisDivs}
                            </div>
                        </div>
                        <div className='demo-step-review-todos'>
                            <h3>
                                And follow all the steps above.
                            </h3>
                            <div>
                                May also use things like Stackoverflow.
                            </div>
                        </div>
                        <div className='demo-step-review-todos'>
                            <h3>
                                May also need a devops...
                            </h3>
                            <div>
                                Things like https, CORS, callbacks, JWT, etc.
                            </div>
                        </div>
                    </td>
                    <td align='center' width='50%'>
                        <div className='demo-step-review-todos-green'>
                            <h3>
                                Or use Sandgit:
                            </h3>
                            <div>
                                <p>
                                    One click and you get a sandbox with all the services you selected properly set up as an MVP.
                                </p>
                                <div>
                                    <Button label='See how it works' onClick={() => setStep('details')} />
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
        </div >;

    const theCloud =
        <div>
            <div className='demo-details-cloud'>
                <p style={{ margin: '2em' }}>
                    Your favorite cloud...
                </p>
                <div className='demo-details-vm'>
                    <p style={{ margin: '2em' }}>
                        A virtual machine...
                    </p>
                    <div className='demo-details-services'>
                        <p style={{ margin: '2em' }}>
                            Running following services:
                        </p>
                        <div>
                            {stackDivs}
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    const theCloud2 =
        <div>
            <div className='demo-details-cloud'>
                <p style={{ margin: '2em' }}>
                    Cloud...
                </p>
                <div className='demo-details-vm'>
                    <p style={{ margin: '2em' }}>
                        A virtual machine...
                    </p>
                    <div className='demo-details-services'>
                        <p style={{ margin: '2em' }}>
                            Running following services:
                        </p>
                        <div>
                            {stackDivs}
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    const theNetwork =
        <div>
            <div className='demo-details-vpn'>
                <p style={{ margin: '2em' }}>
                    VPN...
                </p>
                <div className='demo-details-ssh'>
                    ssh...
                </div>
            </div>
            {/* <div className='demo-details-screen-share'>
                <div align='center'>
                    <img src={img_forbidden} width='32em'></img>
                </div>
                <div>
                    No need for screen share!
                </div>
            </div> */}
        </div>;
    const thePC =
        <div className='demo-details-pc'>
            <p style={{ margin: '2em' }}>
                Your computer...
            </p>
            <div className='demo-details-ide'>
                Your favorite IDE / code editor...
            </div>
        </div>;
    const thePC2 =
        <div className='demo-details-pc'>
            <p style={{ margin: '2em' }}>
                Jane's Laptop
            </p>
            <div className='demo-details-ide'>
                Jane's favorite IDE / code editor...
            </div>
        </div>;
    const youDiv =
        <div className='demo-details-you'>
            You
        </div>;

    const colleagueDiv =
        <div className='demo-details-you'>
            Jane
        </div>;
    const stepDetails =
        <div className='demo-step-details'>
            <h3>
                That's how it works...
            </h3>
            <table>
                <tr>
                    <td className='demo-details-comp'>
                        {youDiv}
                    </td>
                    <td className='demo-details-comp'>
                        {thePC}
                    </td>
                    <td className='demo-details-comp'>
                        {theNetwork}
                    </td>
                    <td className='demo-details-comp'>
                        {theCloud}
                    </td>
                </tr>
                {
                    shouldSeeMore == true ?
                        <tr>
                            <td>
                                {colleagueDiv}
                            </td>
                            <td>
                                {
                                    isCloned == true ? <div>{thePC2}</div> : null
                                }
                            </td>
                            <td>
                                {
                                    isCloned == true ?
                                        <div>
                                            {theNetwork}
                                        </div> : null
                                }
                            </td>
                            <td align='center'>
                                {
                                    isCloned == false ?
                                        <Button
                                            label='Just clone this box'
                                            className="p-button-success demo-pulse"
                                            onClick={() => setIsCloned(true)}
                                        />
                                        : <div>
                                            {theCloud2}
                                        </div>
                                }
                            </td>
                        </tr>
                        : null
                }
            </table>
            <div>
                {
                    shouldSeeMore == false ?
                        <div>
                            <h4>
                                Want to see more?
                            </h4>
                            <div>
                                <Button label='Continue' onClick={() => setShouldSeeMore(true)} />
                            </div>
                        </div> : null
                }
            </div>
            {
                shouldSeeMore == true && isCloned == false ?
                    <div className='demo-details-new-colleague'>
                        <h2>
                            You have a new colleague and they need your help for the development setup...
                        </h2>
                    </div> : null
            }
            {
                shouldSeeMore == true && isCloned == true ?
                    <div>
                        <Button label="Close the demo" className="p-button-danger" onClick={() => navigate('/')} />
                    </div> : null
            }
        </div>;

    let content = null;
    switch (step) {
        case 'wait-to-start':
            content = stepWaitToStart;
            break;
        case 'ack-role':
            content = stepAckRole;
            break;
        case 's1-choose-stack':
            content = stepS1ChooseStack;
            break;
        case 'review':
            content = stepS1Review;
            break;
        case 'details':
            content = stepDetails;
            break;
        default:
            break;
    }

    return (
        <div align='center'>
            <MarketinMenu />
            <div className='demo-parent'>
                {content}
            </div>
        </div>
    );
}

export default Demo;