import axios from "axios";

export const logEvent = (event) => {
    const data = {
        'event': event,
        'data': '',
    };

    axios.post(
        '/logevent/',
        data
    ).then(
        (_response) => { }
    ).catch(
        (_error) => { }
    );
};