import './InternChartMarketingPage.css';

import InternChartBase from './InternChartBase';

function InternChartMarketingPage() {
    return (
        <div>
            <InternChartBase
                title='Marketing page / total wiews'
                dataset='marketing_page_views'
                type='bar'
            />
        </div>
    );
}

export default InternChartMarketingPage;