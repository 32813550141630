import V3Button from "../button/V3Button";
import { Dialog } from 'primereact/dialog';
import { useState } from "react";
import { deleteMembership } from "../../services/ServiceMembership.ts";

function V3ProjectMembership({ membership, loadMemberships }) {
    const [showDialog, setShowDialog] = useState(false);
    const rmMembership = () => {
        deleteMembership(membership.id).then(
            (_response) => {
                setShowDialog(false);
                loadMemberships();
            }
        )
    };

    return (
        <div className="v3-feed-main-item-center">
            <div>
                <h3>
                    {membership.email}
                </h3>
            </div>
            <div>
                membership id: &nbsp; {membership.id}
            </div>
            <div>
                {membership.status}
            </div>
            <V3Button label='MANAGE' onClick={() => setShowDialog(true)} />
            <Dialog
                header='Membership'
                visible={showDialog}
                onHide={() => setShowDialog(false)}
                headerStyle={{
                    backgroundImage: 'none',
                    backgroundColor: 'black',
                    color: 'white',
                    borderColor: 'red',
                    borderStyle: 'solid',
                    borderBottom: '0'
                }}
                contentStyle={{
                    backgroundImage: 'none',
                    backgroundColor: 'black',
                    color: 'white',
                    borderColor: 'red',
                    borderStyle: 'solid',
                    borderTop: '0'
                }}
            >
                <div>
                    <h3>
                        {membership.email}
                    </h3>
                </div>
                <div>
                    membership id: &nbsp; {membership.id}
                </div>
                <div>
                    {membership.status}
                </div>
                <div style={{ display: 'flex', marginTop: '1em' }}>
                    <V3Button
                        option='danger'
                        label='REVOKE'
                        onClick={rmMembership}
                    />
                    <V3Button
                        label='CLOSE'
                        onClick={() => setShowDialog(false)}
                    />
                </div>
            </Dialog>

        </div>
    );
}

export default V3ProjectMembership;