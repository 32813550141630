import './ProjectsForeign.css';
import axios from 'axios';
import { useState, useEffect } from 'react';

import { PageHeader } from '../header/PageHeader';
import ProjectsForeignCover from './ProjectForeignCover';
import { getMemberships } from '../Services';


function ProjectsForeign() {
    const [memberships, setMemberships] = useState([]);
    useEffect(
        () => getMemberships().then(
            (result) => {
                setMemberships(result.data);
            })
        ,
        [],
    );

    const divSharedProjects = memberships.map(
        m =>
            <ProjectsForeignCover
                data={m}
            />
    );

    if (memberships.length === 0) {
        return (
            <div>
                <PageHeader mode='account' />
                <div align='center' style={{ padding: '3em' }}>
                    <h2>You don't have any shared project.</h2>
                </div>
            </div>
        );
    }

    return (
        <div>
            <PageHeader mode='account' />
            <div className='projects-main-title' align='center'>
                <h3> Shared projects ({memberships.length})</h3>
            </div>
            <div
                style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}
            >
                {divSharedProjects}
            </div>
        </div>
    );
}

export default ProjectsForeign;