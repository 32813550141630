import './V3Home.css';
import bg from "./resources/tech.jpg";
import img_man from './resources/man.png';
import img_c2 from './resources/c2.png';
import img_cm from './resources/cm.jpg';
import img_a2_black from './resources/a2_black.png';

import V3Card from './V3Card';
import V3CardWithPoint from './V3CardWithPoint';
import V3Menu from './menu/V3Menu';
import { useNavigate } from 'react-router-dom';

function V3Home() {
    const navigate = useNavigate();

    return (
        <div>
            <div
                style={{
                    backgroundImage: `url(${bg})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}
                className='v3'
            >
                <div className='v3-bg'>
                    <V3Menu />
                    <div
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        <img src={img_man} width='400em' />
                        <div>
                            <div className='v3-header-title'>
                                EASY. REMOTE. SECURE.
                            </div>
                            <div className='v3-header-subtitle'>
                                10+ YEARS OF FAANG/MAANG EXPERIENCE
                            </div>
                            <div className='v3-header-body'>
                                To help IT teams focus on what matters. Accelerate development easy, remote and secure.
                            </div>
                            <div
                                className='v3-header-demo-button'
                                onClick={() => navigate('/bookdemo')}
                            >
                                DEMO
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='v3-down' align='center'>
                <div style={{ position: 'relative' }}>
                    <div className='v3-line-b' />
                    <div style={{ position: 'relative' }}>
                        <div className='v3-line' />
                        <div className='v3-card-list'>
                            <div className='v3-line-text'>
                                Development
                            </div>
                            <V3Card
                                title='ANY CLOUD'
                                imgSrc={img_c2}
                                duration='1s'
                                color='pink'
                            />
                            <V3Card
                                title='ANY &nbsp; STACK'
                                imgSrc={img_cm}
                                duration='2s'
                                color='pink'
                            />
                            <V3Card
                                title='ANY &nbsp; IDE'
                                imgSrc={img_c2}
                                duration='3s'
                                color='pink'
                            />
                        </div>
                        <div className='v3-card-list'>
                            <V3Card
                                title='ANY PIPELINE'
                                imgSrc={img_c2}
                                duration='4s'
                                color='blue'
                            />
                            <V3Card
                                title='ANY PLUGIN'
                                imgSrc={img_cm}
                                duration='5s'
                                color='blue'
                            />
                            <V3Card
                                title='ANY SOURCE CONTROL'
                                imgSrc={img_c2}
                                duration='6s'
                                color='blue'
                            />
                        </div>
                    </div>
                    <V3CardWithPoint
                        title='Any Cloud'
                        body='We are very open in terms of Cloud. You can plug in your favorite cloud at no additional costs.'
                    />
                </div>

                <div style={{ position: 'relative' }}>
                    <div className='v3-line-b' />
                    <div style={{ position: 'relative' }}>
                        <div className='v3-line' />
                        <div className='v3-card-list'>
                            <V3Card
                                title='AWS'
                                imgSrc={img_c2}
                                duration='7s'
                                color='pink'
                            />
                            <V3Card
                                title='GOOGLE CLOUD'
                                imgSrc={img_cm}
                                duration='8s'
                                color='pink'
                            />
                            <V3Card
                                title='MANY OTHERS'
                                imgSrc={img_c2}
                                duration='9s'
                                color='pink'
                            />
                        </div>
                        <div className='v3-card-list'>
                            <V3Card
                                title='Azure'
                                imgSrc={img_c2}
                                duration='10s'
                                color='blue'
                            />
                            <V3Card
                                title='Digital Ocean'
                                imgSrc={img_cm}
                                duration='11s'
                                color='blue'
                            />
                            <V3Card
                                title='Cloud Flare'
                                imgSrc={img_c2}
                                duration='12s'
                                color='blue'
                            />
                        </div>
                    </div>
                    <V3CardWithPoint
                        title='Any Stack'
                        body='We support anything that can run on your laptop for development and leverage the cloud speed and power.'
                    />
                </div>

                <div style={{ position: 'relative' }}>
                    <div className='v3-line-b' />
                    <div style={{ position: 'relative' }}>
                        <div className='v3-line' />
                        <div className='v3-card-list'>
                            <V3Card
                                title='WEB'
                                imgSrc={img_c2}
                                duration='7s'
                                color='pink'
                            />
                            <V3Card
                                title='MOBILE'
                                imgSrc={img_cm}
                                duration='8s'
                                color='pink'
                            />
                            <V3Card
                                title='AI'
                                imgSrc={img_c2}
                                duration='9s'
                                color='pink'
                            />
                        </div>
                        <div className='v3-card-list'>
                            <V3Card
                                title='Security'
                                imgSrc={img_c2}
                                duration='10s'
                                color='blue'
                            />
                            <V3Card
                                title='Dev Ops'
                                imgSrc={img_cm}
                                duration='11s'
                                color='blue'
                            />
                            <V3Card
                                title='BIG DATA'
                                imgSrc={img_c2}
                                duration='12s'
                                color='blue'
                            />
                        </div>
                    </div>
                    <V3CardWithPoint
                        title='Any IDE'
                        body='Code along with your favorite IDE and plugins. No need to change anything on your development process.'
                    />
                </div>
                <div style={{ position: 'relative' }}>
                    <div className='v3-line-b' />

                    <div style={{ position: 'relative' }}>
                        <div className='v3-line' />
                        <div className='v3-card-list'>
                            <V3Card
                                title='VS Code'
                                imgSrc={img_c2}
                                duration='7s'
                                color='pink'
                            />
                            <V3Card
                                title='VIM'
                                imgSrc={img_cm}
                                duration='8s'
                                color='pink'
                            />
                            <V3Card
                                title='MANY MORE'
                                imgSrc={img_c2}
                                duration='9s'
                                color='pink'
                            />
                        </div>
                        <div className='v3-card-list'>
                            <V3Card
                                title='IntelliJ IDEA'
                                imgSrc={img_c2}
                                duration='10s'
                                color='blue'
                            />
                            <V3Card
                                title='Net Beans'
                                imgSrc={img_cm}
                                duration='11s'
                                color='blue'
                            />
                            <V3Card
                                title='Eclipse'
                                imgSrc={img_c2}
                                duration='12s'
                                color='blue'
                            />
                        </div>
                    </div>
                    <V3CardWithPoint
                        title='Discover More'
                        body='Discover more services we offer and learn more about us on our official channels.'
                    />
                </div>

                <div className='v3-card-list' style={{ marginTop: '15em' }}>
                    <img src={img_a2_black} width='200em' height='200em' />
                    <div className='v3-footer' align='left'>
                        <div className='v3-footer-line'>
                            English
                        </div>
                        <div className='v3-footer-line'>
                            Sign Up Log In Contact Us Terms & Conditions Cookies Pricing
                        </div>
                        <div>
                            Security Partners Cloud Providers Containers IDEs
                        </div>
                        <div className='v3-footer-line'>
                            <b>SandGit @ 2023</b>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default V3Home;