import { useEffect, useState } from "react";
import { getProject } from "../../services/ServiceProject.ts";
import V3Button from "../button/V3Button";
import './../feed/V3Feed.css';
import axios from "axios";
import { getHeaders } from "../../../Services";
import { confirmProjectMembership } from "../../services/ServiceProjectMembership.ts";

function V3ProjectInvite({
    loadProjects,
    projectMembership,
    loadProjectMemberships,
}) {
    const [project, setProject] = useState(null);
    const [sender, setSender] = useState(null);

    const loadProject = () => {
        getProject(projectMembership.project_id).then(
            (response) => {
                setProject(response.data);
            }
        )
    };
    useEffect(
        () => {
            loadProject();
        },
        [],
    );

    // TODO: make this a service file.
    const getSender = () => {
        axios.get(
            `/user/${projectMembership.user_id}/`,
            {
                headers: getHeaders(),
            }
        ).then(
            (response) => {
                setSender(response.data)
            }
        )
    }
    useEffect(
        () => getSender(),
        [],
    );

    if (project == null || sender == null) {
        return (
            <div>Loading</div>
        );
    }

    const joinProject = () => {
        confirmProjectMembership(projectMembership.id).then(
            (_response) => {
                loadProjectMemberships();
                loadProjects();
            },
        );
    };

    return (
        <div className='v3-feed-main-item-center'>
            <h2>
                You are invited to join a project.
            </h2>
            <div>
                from: &nbsp; <b>{sender.email}</b>
            </div>
            <div>
                project title: &nbsp; <b>{project.title}</b>
            </div>
            <div style={{ display: 'flex', marginTop: '2em' }}>
                <V3Button
                    label='JOIN'
                    option='success'
                    onClick={joinProject}
                />
                <V3Button
                    label='DECLINE'
                    disabled={true}
                />
            </div>
        </div>
    );
}

export default V3ProjectInvite;