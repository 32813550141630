import './TemplateCover.css';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'primereact/button';
import { getHeaders } from '../Services';

function TemplateCover({ data, allowCopy, reloadData }) {
    const headers = getHeaders();
    const [disabledCopy, setDisabledCopy] = useState(false);

    const navigate = useNavigate();

    const copyToMyTemplates = () => {
        axios.post(
            '/template/',
            {
                'template_id': data.id
            },
            {
                headers: headers
            }
        ).then(
            (_response) => {
                setDisabledCopy(true);
                reloadData();
            }
        )
    }

    const divServices = data.services.map(
        s => <div className='template-cover-service'>
            {s}
        </div>
    );

    return (
        <div className='template-cover'>
            <h2>
                {data.title}
            </h2>
            <p style={{ paddingLeft: '0.5em', fontSize: '0.8em' }}>

                {
                    allowCopy === false || disabledCopy === true
                        ? 'already in your list of templates'
                        : 'available'
                }
            </p>

            <table width='100%'>
                <tr>
                    <td>
                        <div style={{ display: 'flex' }}>
                            {divServices}
                        </div>
                    </td>
                    <td>
                        <div style={{ padding: '0.1em' }} align='right'>
                            {

                                allowCopy === false || disabledCopy === true
                                    ?
                                    <Button
                                        label='See my list'
                                        className='p-button-sm p-button-secondary'
                                        onClick={() => navigate('/templates/my/')}
                                    />
                                    : <Button
                                        label='Copy to my templates'
                                        className='p-button-sm'
                                        onClick={
                                            () => {
                                                copyToMyTemplates();
                                            }
                                        }
                                        disabled={allowCopy === false || disabledCopy === true}
                                    />
                            }
                        </div>
                    </td>
                </tr>
            </table>

        </div >
    );
}

export default TemplateCover;