import axios from "axios";
import Cookies from "universal-cookie";

function getHeaders() {
    const cookies = new Cookies();
    return {
        'Authorization': 'Bearer ' + cookies.get('token')
    }
}

function getDevserverEdges(projectID: number) {
    return axios.get(
        '/edgeprojecttodevserver/',
        {
            headers: getHeaders(),
            params: {
                project_id: projectID
            },
        }
    );
}

/* Assigns an existing devserver. */
function createDevserverEdge(
    project_id: Number,
    template: any,
    devserver_name: String,
    devserver_password: String,
) {
    return axios.post(
        '/edgeprojecttodevserver/',
        {
            project_id: project_id,
            default_services: template.origin,
            devserver_name: devserver_name,
            devserver_password: devserver_password,
        },
        {
            headers: getHeaders(),
        }
    );
}

export { getDevserverEdges, createDevserverEdge };