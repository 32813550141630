import './InternChartDemoStart.css';

import { useEffect, useState } from 'react';
import { Chart } from 'primereact/chart';
import axios from 'axios';
import InternChartBase from './InternChartBase';

function InternChartDemoStart() {
    return (
        <InternChartBase
            title='Create Demo User / clicks'
            dataset='click_create_demo_account'
            type='line'
        />
    );
}

export default InternChartDemoStart;