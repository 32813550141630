import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import V3Menu from "../menu/V3Menu";
import './V3BookDemo.css';

import { useNavigate } from 'react-router-dom';


import axios from "axios";
import { useState } from "react";

function V3BookDemo() {
    const [step, setStep] = useState('input');
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const addDemoRequest = () => {
        axios.post(
            '/demorequest/',
            {
                email: email
            }
        ).then(
            (_response) => {
                setStep('success');
            }
        )
    }

    return (
        <div>
            <div className='v3-bg'>
                <V3Menu />
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {step == 'input'
                        ? <div>
                            <div className="v3-h3 v3-bd-item">
                                Request a demo
                            </div>
                            <div className="v3-bd-item">
                                <div className="v3-bd-item">
                                    <InputText
                                        placeholder='email'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="v3-bd-item">
                                    <Button
                                        label="Request"
                                        onClick={addDemoRequest}
                                        disabled={email == ''}
                                    />
                                </div>
                            </div>
                        </div>
                        : null
                    }
                    {step == 'success'
                        ? <div>
                            <div className="v3-h3 v3-bd-item">
                                Your demo request was successfully registered.
                            </div>
                            <div className="v3-bd-item">
                                <div className="v3-bd-item" style={{ color: 'white' }}>
                                    You will be contacted soon.
                                </div>
                                <div className="v3-bd-item">
                                    <Button
                                        label="Home"
                                        onClick={() => navigate('/')}
                                    />
                                </div>
                            </div>
                        </div>
                        : null
                    }
                </div>
            </div>
        </div>
    );
}

export default V3BookDemo;