import { useEffect, useState } from 'react';
import { getTemplate } from '../../services/ServiceTemplates.ts';
import V3Button from '../button/V3Button';
import './../feed/V3Feed.css';

import { Dialog } from 'primereact/dialog';
import { deleteTemplateEdge } from '../../services/ServiceTemplates.ts';
import { createDevserverEdge } from '../../services/ServiceDevserverEdge.ts';
import { getDevserversProvisioning } from '../../services/ServiceDevserver.ts';
import { getDevserversProvisional } from '../../services/ServiceDevserver.ts';
import { scheduleDevserverProvisioning } from '../../services/ServiceDevserver.ts';
import { ProgressSpinner } from 'primereact/progressspinner';
import { deleteDevserver } from '../../services/ServiceDevserver.ts';

function V3ProjectTemplate({
    projectID,
    templateEdge,
    loadTemplateEdges,
    goToDevserversPage,
}) {
    const [template, setTemplate] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [showDialogCreateDev, setShowDialogCreateDev] = useState(false);
    const [showDialogProvisioning, setShowDialogProvisioning] = useState(false);
    const [devserverName, setDevserverName] = useState('');
    const [devserverPassword, setDevserverPassword] = useState('');
    const [devProvisioning, setDevProvisioning] = useState([]);
    const [devProvisional, setDevProvisional] = useState([]);
    const [createDevserverStatus, setCreateDevserverStatus] = useState('input');

    const loadDevserversProvisioning = () => {
        getDevserversProvisioning(projectID).then(
            (response) => {
                setDevProvisioning(response.data);
                setTimeout(
                    () => loadDevserversProvisioning(),
                    5000,
                );
            }
        );
    }
    useEffect(
        () => {
            loadDevserversProvisioning();
        },
        [projectID],
    );

    const loadDevserversProvisional = () => {
        getDevserversProvisional(projectID).then(
            (response) => {
                setDevProvisional(response.data);
                setTimeout(
                    () => loadDevserversProvisional(),
                    5000,
                );
            }
        );
    };

    useEffect(
        () => {
            loadDevserversProvisional();
        },
        [projectID],
    );

    useEffect(
        () => {
            getTemplate(templateEdge.dev_template_id).then(
                (response) => setTemplate(response.data),
            );
        },
        [templateEdge]
    );

    if (template == null) {
        return (
            <div className="v3-feed-main-item-center">
                Loading...
            </div>
        );
    }

    const rmTemplateEdge = () => {
        deleteTemplateEdge(templateEdge.id).then(
            (_response) => {
                loadTemplateEdges();
                setShowDialog(false);
            }
        );
    };

    const addTemplateEdge = () => {
        setCreateDevserverStatus('creating');
        createDevserverEdge(
            projectID,
            template,
            devserverName,
            devserverPassword,
        ).then(
            (_response) => {
            }
        );
        setCreateDevserverStatus('input');
        setShowDialogCreateDev(false);
        goToDevserversPage();

    };

    const scheduleDev = () => {
        scheduleDevserverProvisioning(projectID).then(
            (_response) => {
                loadDevserversProvisioning();
            }
        );
    };

    const clearQueueProvisional = () => {
        devProvisional.map(
            d => deleteDevserver(d.id).then(
                (_response) => {
                    loadDevserversProvisional();
                }
            )
        )
    }

    const divDevsProvisioning = devProvisioning.map(
        d => <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '1em' }}>
                <ProgressSpinner style={{ width: '2em', height: '2em' }} />
            </div>
            <div>
                provisioning one devserver...
            </div>
        </div>
    );
    const divDevsProvisional = devProvisional.map(
        d => <div>{d.name + ' / ' + d.url ?? 'url not set yet' + ' / ' + d.status}</div>
    );

    return (
        <div className="v3-feed-main-item-center">
            <div>
                template
            </div>
            <div>
                <h2>
                    {template.title}
                </h2>
            </div>
            <div>
                edge id: &nbsp; {templateEdge.id}
            </div>
            <div>
                template id: &nbsp; {template.id}
            </div>
            <div>
                origin: &nbsp; {template.origin ?? 'n/a'}
            </div>
            <div style={{ display: 'flex' }}>
                <V3Button
                    label='CREATE DEVSERVER'
                    onClick={() => setShowDialogCreateDev(true)}
                    disabled={devProvisional.length == 0}
                />
                <Dialog
                    header='Create devserver'
                    visible={showDialogCreateDev}
                    onHide={() => setShowDialogCreateDev(false)}
                    headerStyle={{
                        backgroundImage: 'none',
                        backgroundColor: 'black',
                        color: 'white',
                        borderColor: 'red',
                        borderStyle: 'solid',
                        borderBottom: '0'
                    }}
                    contentStyle={{
                        backgroundImage: 'none',
                        backgroundColor: 'black',
                        color: 'white',
                        borderColor: 'red',
                        borderStyle: 'solid',
                        borderTop: '0'
                    }}
                >
                    <div style={{ marginRight: '3em' }}>
                        {createDevserverStatus == 'input'
                            ? <div>
                                <div>
                                    Just need a few things to continue.
                                </div>
                                <div style={{ marginTop: '1em', marginBottom: '1em' }}>
                                    <div>
                                        <input
                                            className='v3-input'
                                            type='text'
                                            placeholder='name, eg.: dev123'
                                            value={devserverName}
                                            onChange={(e) => setDevserverName(e.target.value)}
                                            autoComplete='new-password'
                                        />
                                    </div>
                                    <div>
                                        <input
                                            className='v3-input'
                                            type='password'
                                            placeholder='password'
                                            value={devserverPassword}
                                            onChange={(e) => setDevserverPassword(e.target.value)}
                                            autoComplete='new-password'
                                        />
                                    </div>
                                </div>
                                <div style={{ marginTop: '2em', display: 'flex' }}>
                                    <V3Button
                                        label='CREATE'
                                        option='danger'
                                        onClick={addTemplateEdge}
                                        disabled={devserverName == '' || devserverPassword == ''}
                                    />
                                    <V3Button
                                        label='CANCEL'
                                        onClick={() => setShowDialogCreateDev(false)}
                                    />
                                </div>
                            </div>
                            : null
                        }
                        {
                            createDevserverStatus == 'creating'
                                ? <div>
                                    Creating devserver now...
                                </div>
                                : null
                        }
                    </div>
                </Dialog>
                <V3Button
                    label='PROVISIONING'
                    onClick={() => setShowDialogProvisioning(true)}
                />
                <Dialog
                    header='Provisioning'
                    visible={showDialogProvisioning}
                    onHide={() => setShowDialogProvisioning(false)}
                    headerStyle={{
                        backgroundImage: 'none',
                        backgroundColor: 'black',
                        color: 'white',
                        borderColor: 'red',
                        borderStyle: 'solid',
                        borderBottom: '0'
                    }}
                    contentStyle={{
                        backgroundImage: 'none',
                        backgroundColor: 'black',
                        color: 'white',
                        borderColor: 'red',
                        borderStyle: 'solid',
                        borderTop: '0'
                    }}
                >
                    <div>
                        <div>
                            <h3>Ready:</h3>
                            <div>
                                {divDevsProvisional.length > 0 ? divDevsProvisional : 'empty'}
                            </div>
                            <V3Button
                                label='CLEAR QUEUE'
                                option='danger'
                                disabled={devProvisional.length == 0}
                                onClick={clearQueueProvisional}
                            />
                        </div>
                        <div style={{ marginTop: '3em' }}>
                            <h3>Provisioning now:</h3>
                            <div>
                                {divDevsProvisioning.length > 0 ? divDevsProvisioning : 'empty'}
                            </div>
                            <div style={{ display: 'flex' }}>
                                <V3Button
                                    label='CLEAR QUEUE'
                                    option='danger'
                                    disabled={true}
                                />
                                <V3Button
                                    label='SCHEDULE'
                                    onClick={() => scheduleDev()}
                                />
                            </div>
                        </div>
                    </div>
                </Dialog>
                <V3Button
                    label='REMOVE'
                    option='danger'
                    onClick={() => setShowDialog(true)}
                />
                <Dialog
                    header='Remove template'
                    visible={showDialog}
                    onHide={() => setShowDialog(false)}
                    headerStyle={{
                        backgroundImage: 'none',
                        backgroundColor: 'black',
                        color: 'white',
                        borderColor: 'red',
                        borderStyle: 'solid',
                        borderBottom: '0'
                    }}
                    contentStyle={{
                        backgroundImage: 'none',
                        backgroundColor: 'black',
                        color: 'white',
                        borderColor: 'red',
                        borderStyle: 'solid',
                        borderTop: '0'
                    }}
                >
                    <div style={{ marginRight: '3em' }}>
                        <div>
                            Are you sure you want to remote this template?
                        </div>
                        <div style={{ marginTop: '2em', display: 'flex' }}>
                            <V3Button
                                label='REMOVE'
                                option='danger'
                                onClick={rmTemplateEdge}
                            />
                            <V3Button
                                label='CANCEL'
                                onClick={() => setShowDialog(false)}
                            />
                        </div>
                    </div>
                </Dialog>
            </div>
        </div>
    );
}

export default V3ProjectTemplate;