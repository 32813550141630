import InternChartBase from './InternChartBase';
import './InternChartMAP.css';

function InternChartMAP() {

    return (
        <InternChartBase
            title='MAP'
            dataset='map'
            type='bar'
        />
    );
}

export default InternChartMAP;