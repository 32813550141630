import InternChartBase from './InternChartBase';
import './InternChartDAP.css';

function InternChartDAP() {

    return (
        <InternChartBase
            title='DAP'
            dataset='dap'
            type='bar'
        />
    );
}

export default InternChartDAP;