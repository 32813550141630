import axios from 'axios';
import Cookies from 'universal-cookie';

function getHeaders() {
    const cookies = new Cookies();
    return {
        'Authorization': 'Bearer ' + cookies.get('token')
    }
}

function getProjects(
    filter: String,
) {
    return axios.get(
        '/project',
        {
            headers: getHeaders(),
            params: {
                filter: filter
            }
        }
    );
}

function getProject(id: Number) {
    return axios.get(
        `/project/${id}`,
        {
            headers: getHeaders()
        }
    );
}

function createProject(data: any) {
    return axios.post(
        '/project/',
        data,
        {
            headers: getHeaders(),
        }
    );
}

function deleteProject(project_id: Number) {
    return axios.delete(
        `/project/${project_id}/`,
        {
            headers: getHeaders(),
        }
    );
}

export { getProjects, getProject, createProject, deleteProject };