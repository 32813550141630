import V3FeedMenu from "./menu/V3FeedMenu";
import V3LeftMenu from './left_menu/V3LeftMenu';
import V3Account from "./account/V3Account";
import V3Logout from "./logout/V3Logout";
import V3Project from "./project/V3Project";
import V3Home from "./feed/V3Home";

import { getProjects } from '../services/ServiceProject.ts';
import { useEffect, useState } from 'react';
import { getProjectMemberships } from '../services/ServiceProjectMembership.ts';
function V3Product() {
    const [selectedItem, setSelectedItem] = useState(
        {
            type: 'menu',
            value: 'home',
        }
    );

    const [projects, setProjects] = useState([]);
    const [projectMemberships, setProjectMemberships] = useState([]);

    const loadProjects = () => {
        getProjects('all').then(
            (response) => {
                setProjects(response.data);
                setSelectedItem(
                    {
                        type: 'menu',
                        value: 'home',
                    }
                );
            },
        );
    };
    useEffect(
        () => {
            loadProjects();
        },
        [],
    );

    const loadProjectMemberships = () => {
        getProjectMemberships().then(
            (response) => {
                setProjectMemberships(response.data);
            }
        );
    }
    useEffect(
        () => {
            loadProjectMemberships();
        },
        [],
    );

    return (
        <div className="v3-feed">
            <div style={{ padding: '0.5em', }}>
                <V3FeedMenu />
            </div>
            <div className='v3-feed-main'>
                <div className='v3-feed-main-item-left'>
                    <V3LeftMenu
                        projects={projects}
                        selectedItem={selectedItem.value}
                        onClickItem={(item) => setSelectedItem(item)}
                        notificationsCount={projectMemberships.length}
                    />
                </div>
                <div style={{ width: '100%', marginRight: '2em' }}>
                    <div>
                        {
                            selectedItem.value == 'home'
                                ? <V3Home
                                    loadProjects={loadProjects}
                                    projectMemberships={projectMemberships}
                                    loadProjectMemberships={loadProjectMemberships}
                                />
                                : null
                        }
                        {
                            selectedItem.value == 'account' ? <V3Account /> : null}
                        {
                            selectedItem.value == 'logout' ? <V3Logout /> : null}
                        {
                            selectedItem.type == 'project'
                                ? <V3Project
                                    projectID={selectedItem.value}
                                    loadProjects={loadProjects}
                                />
                                : null
                        }
                    </div>
                </div>
                <div className='v3-feed-main-item-right'></div>
            </div>
        </div>
    );
}

export default V3Product;