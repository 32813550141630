import './BenefitLarge.css';

function BenefitLarge({ img_src, title, body, quote }) {
    return (
        <div className='exp-container'>
            <div>
                <img src={img_src} className='exp-img'></img>
            </div>
            <div align='center'>
                <h1>{title}</h1>
            </div>
            <div align='center' className='exp-content'>
                {body}
            </div>
            <div align='center' className='exp-quote'>
                {quote}
            </div>
        </div>
    );
}

export { BenefitLarge };