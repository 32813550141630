import InternChartBase from './InternChartBase';
import './InternChartProjectsTotal.css';

function InternChartProjectsTotal() {
    return (
        <InternChartBase
            title='Projects (cumulative)'
            dataset='projects_total'
            type='bar'
        />
    );
}

export default InternChartProjectsTotal;