import './Notification.css';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import Cookies from 'universal-cookie';


function Notification({ title, content }) {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div
            className='notification'
            onClick={() => setIsExpanded(!isExpanded)}
        >
            <h4>
                <table>
                    <tr>
                        <td>
                            <i className="pi pi-bell" style={{ 'fontSize': '2em' }}></i>
                        </td>
                        <td>
                            {title}
                        </td>
                    </tr>
                </table>
            </h4>
            <div>
                {
                    isExpanded == true ? content : null
                }
            </div>
        </div>
    );
}

export default Notification;