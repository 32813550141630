import './../feed/V3Feed.css';
import V3Button from '../button/V3Button';
import { useState } from 'react';
import { createProject } from '../../services/ServiceProject.ts';

function V3ProjectNew({ loadProjects }) {
    const [step, setStep] = useState('input');
    const [title, setTitle] = useState('');

    const createNewProject = () => {
        setStep('processing');
        const data = {
            title: title
        };
        createProject(data).then(
            (_response) => {
                setStep('success');
                loadProjects();
            }
        );
    }

    return (
        <div className='v3-feed-main-item-center'>
            {
                step == 'input' ?
                    <div>
                        <h2>
                            Create a new project
                        </h2>
                        <div>
                            <input
                                className='v3-input'
                                type='text'
                                placeholder='title'
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </div>
                        <div style={{ marginTop: '2em' }}>
                            <V3Button
                                label='CREATE'
                                onClick={createNewProject}
                            />
                        </div>
                    </div> : null
            }
            {
                step == 'processing' ?
                    <div>
                        <h2>
                            Project is being created...
                        </h2>
                    </div>
                    : null
            }
            {
                step == 'success' ?
                    <div>
                        <h2>
                            Project was successfully created.
                        </h2>
                    </div>
                    : null
            }
        </div>
    );
}

export default V3ProjectNew;