import './ProjectInviteCover.css';

import axios from 'axios';
import { useState, useEffect } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { getHeaders } from '../Services';

function ProjectInviteCover({ data, reloadData }) {
    const navigate = useNavigate();
    const headers = getHeaders();

    const [project, setProject] = useState(null);
    const [sender, setSender] = useState(null);

    const getProject = () => {
        axios.get(
            `/project/${data.project_id}/`,
            {
                headers: headers
            }
        ).then(
            (response) => {
                setProject(response.data);
            }
        );
    };
    useEffect(
        () => getProject(),
        [],
    );
    const getSender = () => {
        axios.get(
            `/user/${data.user_id}/`,
            {
                headers: headers
            }
        ).then(
            (response) => {
                setSender(response.data)
            }
        )
    }
    useEffect(
        () => getSender(),
        [],
    );

    const decline = () => {
        axios.delete(
            `/projectmembership/${data.id}/`,
            {
                headers: headers,
            }
        ).then(
            (response) => {
                reloadData();
            }
        )
    }
    const joinProject = () => {
        axios.patch(
            `/projectmembership/${data.id}/`,
            {
                status: 'confirmed',
            },
            {
                headers: headers,
            }
        ).then(
            (response) => {
                reloadData();
                navigate('/projects/shared');
            }
        )
    }

    if (project == null || sender == null) {
        return (
            <div>
                <ProgressSpinner />
            </div>
        );
    }

    return (
        <div className='project-invite-cover'>
            <h3>Project invite</h3>
            <div className='pic-tab'>
                from:
                <div className='pic-item'>
                    <b>{sender.email}</b>
                </div>
            </div>
            <div className='pic-tab'>
                project:
                <div className='pic-item'>
                    <b>{project.title}</b>
                </div>
            </div>
            <div className='pic-tab'>
                description:
                <div className='pic-item'>
                    {project.description}
                </div>
            </div>

            <div className='pic-tab'>
                <table>
                    <tr>
                        <td>
                            <Button
                                label='Join'
                                className='p-button-sm'
                                onClick={joinProject}
                            />
                        </td>
                        <td>
                            <Button
                                label='Decline'
                                className='p-button-sm p-button-danger'
                                onClick={decline}
                            />
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    );
}

export default ProjectInviteCover;