import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import './ProjectDetailsTemplateDialog.css';

import { useState } from 'react';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Devserver } from '../common/devserver/Devserver';
import { getHeaders } from '../Services';

function ProjectDetailsTemplateDialog({
    projectID,
    edge_data,
    template_data,
    reloadData,
}) {
    const headers = getHeaders();
    const [devserverName, setDevserverName] = useState(null);
    const [devserverPassword, setDevserverPassword] = useState(null);
    const [step, setStep] = useState('init');
    const [newDevserver, setNewDevserver] = useState(null);

    const removeFromProject = () => {

        axios.delete(
            `/edgeprojecttodevtemplate/${edge_data.id}/`,
            {
                headers: headers
            }
        ).then(
            (_response) => { }
        )
    };

    const createDevserver = () => {
        setStep('creating');
        axios.post(
            '/edgeprojecttodevserver/',
            {
                project_id: projectID,
                default_services: template_data.origin,
                devserver_name: devserverName,
                devserver_password: devserverPassword,
            },
            {
                headers: headers
            }
        ).then(
            (response) => {
                setNewDevserver(response.data);
                // reloadData();
                setStep('success');
            }
        )
    };

    const divNewServer =
        <div>
            <div align='center'>
                <table>
                    <tr>
                        <td>
                            <i className="pi pi-server" style={{ fontSize: '3em' }} />
                        </td>
                        <td>
                            <h2>
                                {template_data.title} (template box)
                            </h2>
                        </td>
                    </tr>
                </table>
            </div>
            <h3>
                Create a new devserver
            </h3>
            <div style={{ padding: '1em' }}>
                A new devserver will be created based on this template. Just
                need few details to customize it.
            </div>
            <div style={{ padding: '1em' }} align='center'>
                <table>
                    <tr>
                        <td>
                            <InputText
                                placeholder='devserver name'
                                onChange={(e) => setDevserverName(e.target.value)}
                                value={devserverName}
                            />
                        </td>
                        <td>
                            <InputText
                                placeholder='new password'
                                type='password'
                                onChange={(e) => setDevserverPassword(e.target.value)}
                                value={devserverPassword}
                            />
                        </td>
                    </tr>
                </table>
            </div>
            <div style={{ padding: '1em' }} align='center'>
                <Button
                    label='Create devserver'
                    className='p-button-success p-button-sm'
                    onClick={createDevserver}
                    disabled={devserverName === null || devserverName === ''}
                />
            </div>
            <div style={{ margin: '1em' }} align='left'>
                <h3>
                    Remove the template
                </h3>
                <div style={{ padding: '1em' }}>
                    Remove this template from current project. Members
                    will not be able to create these types of devservers
                    anymore.
                </div>
                <div align='center'>
                    <Button
                        label='Remove'
                        className='p-button-danger p-button-sm'
                        onClick={removeFromProject}
                    />
                </div>
            </div>
        </div>;

    const divCreatingDevserver =
        <div align='center'>
            <ProgressSpinner />
            <div style={{ margin: '1em' }}>
                Your devserver is being created right now...
            </div>
        </div>;

    const divSuccess =
        <div align='center'>
            <Devserver
                data={newDevserver}
                reloadDevservers={() => { }}
            />
        </div>;

    return (
        <div>
            <div style={{ margin: '1em' }} align='left'>
                {step === 'init' ? divNewServer : null}
                {step === 'creating' ? divCreatingDevserver : null}
                {step === 'success' ? divSuccess : null}
            </div>
        </div>
    );
}

export default ProjectDetailsTemplateDialog;