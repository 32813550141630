import classNames from 'classnames';
import './V3Button.css';

function V3Button({ label, option, onClick, disabled }) {
    return (
        <div
            className={
                classNames(
                    {
                        'v3-button': true,
                        'v3-button-danger': option == 'danger',
                        'v3-button-success': option == 'success',
                        'v3-button-disabled': disabled,
                    }
                )
            }
            onClick={onClick}
        >
            {label}
        </div>
    );
}

export default V3Button;