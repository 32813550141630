import './ProjectDetailsCta.css';

function ProjectDetailsCta({ message, onClick }) {

    return (
        <div
            className='project-details-cta'
            align='center'
            onClick={() => onClick()}
        >
            {message}
        </div>
    );

}

export default ProjectDetailsCta;