import V3Button from "../button/V3Button";
import { Dialog } from 'primereact/dialog';
import { useState } from "react";

import './../feed/V3Feed.css';
import { deleteProject } from "../../services/ServiceProject.ts";

function V3PRojectSettingsDelete({ projectID, loadProjects }) {
    const [showDialog, setShowDialog] = useState(false);
    const [step, setStep] = useState('input');

    const rmProject = () => {
        setStep('processing');
        deleteProject(projectID).then(
            (_response) => {
                loadProjects();
                setStep('success');
            }
        );
    };

    return (
        <div className="v3-feed-main-item-center">
            <h2>
                Delete project
            </h2>
            <div>
                This will permanently delete the project and all data associated with this project.
            </div>
            <V3Button
                option='danger'
                label='DELETE'
                onClick={() => setShowDialog(true)}
            />
            <Dialog
                header="Delete project"
                visible={showDialog}
                onHide={() => setShowDialog(false)}
                // headerClassName='v3-dialog-header'
                // contentClassName='v3-dialog-content'
                headerStyle={{
                    backgroundImage: 'none',
                    backgroundColor: 'black',
                    color: 'white',
                    borderColor: 'red',
                    borderStyle: 'solid',
                    borderBottom: '0'
                }}
                contentStyle={{
                    backgroundImage: 'none',
                    backgroundColor: 'black',
                    color: 'white',
                    borderColor: 'red',
                    borderStyle: 'solid',
                    borderTop: '0'
                }}
            >
                <div style={{ marginRight: '4em' }}>
                    {step == 'input'
                        ? <div>
                            Are you sure you want to permanently delete this project?
                            <div style={{ display: 'flex', marginTop: '2em' }}>
                                <V3Button
                                    option='danger'
                                    label='DELETE'
                                    onClick={rmProject}
                                />
                                <V3Button
                                    label='CANCEL'
                                    onClick={() => setShowDialog(false)}
                                />
                            </div>
                        </div>
                        : null
                    }
                    {
                        step == 'processing'
                            ? <div>
                                Project is being deleted...
                            </div>
                            : null
                    }
                    {
                        step == 'success'
                            ? <div>
                                Project was successfully deleted.
                            </div>
                            : null
                    }
                </div>
            </Dialog>
        </div>
    );
}

export default V3PRojectSettingsDelete;