import './V3Feed.css';
import './../project_menu/V3ProjectMenu.css';

import V3Button from '../button/V3Button';

function V3Feed() {
    return (
        <div>
            <div className='v3-feed-main-item-center'>
                <h1>Welcome!</h1>
                <h3>
                    Welcome onboard!
                    We want you to have the best possible experience on remote development.
                </h3>
                <V3Button label='NEXT' />
            </div>
        </div >
    );
}

export default V3Feed;