import './../project_menu/V3ProjectMenu.css';

import { useState } from 'react';
import classNames from 'classnames';

function V3HomeMenu({ selectedItem, setSelectedItem, notificationsCount }) {
    const items = [
        {
            id: 'feed',
            label: 'Feed',
        },
        {
            id: 'notifications',
            label: notificationsCount > 0
                ? 'Notifications (' + notificationsCount + ' new)'
                : 'Notifications',
        },
        {
            id: 'new_project',
            label: 'New Project',
        },
    ];

    const divMenuItems = items.map(
        item =>
            <div
                className={
                    classNames(
                        {
                            'v3-project-menu-item': true,
                            'v3-project-menu-item-selected': selectedItem == item.id,
                        }
                    )
                }
                onClick={() => setSelectedItem(item.id)}
            >
                {item.label}
            </div>
    );

    return (
        <div className="v3-project-menu">
            {divMenuItems}
        </div>
    );
}

export default V3HomeMenu;