import axios from "axios";

import Cookies from 'universal-cookie';

function getHeaders() {
    const cookies = new Cookies();
    return {
        'Authorization': 'Bearer ' + cookies.get('token')
    }
}

function getMemberships(projectID: Number) {
    return axios.get(
        '/projectmembership/',
        {
            headers: getHeaders(),
            params:
            {
                project_id: projectID,
                usecase: 'project-details'
            }
        }
    );
}

function deleteMembership(membership_id: Number) {
    return axios.delete(
        `/projectmembership/${membership_id}/`,
        {
            headers: getHeaders(),
        }
    );
}

function createMembership(data: any) {
    return axios.post(
        '/projectmembership/',
        data,
        {
            headers: getHeaders(),
        },
    );
}

export { getMemberships, deleteMembership, createMembership };