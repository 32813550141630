import './ContactUs.css';

import { useState } from 'react';

import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { ProgressSpinner } from 'primereact/progressspinner';
import axios from 'axios';

function ContactUs() {
    const [step, setStep] = useState('input');
    const [email, setEmail] = useState(null);
    const [message, setMessage] = useState(null);

    const sendMessage = () => {
        setStep('loading');
        const data = {
            'email': email,
            'body': message,
        };
        axios.post('/message/', data).then(
            (_response) => {
                setStep('done');
            }
        ).catch(
            (_error) => {
                setStep('fail');
            }
        )
    }

    const stepInput = <div>
        <h2 className='h2-style'>
            Contact us
        </h2>
        <div className='contact-item-container'>
            <InputText className='contact-item' placeholder='email' value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className='contact-item-container'>
            <InputTextarea className='' placeholder='write your message here' rows={5} cols={30} value={message} onChange={(e) => setMessage(e.target.value)} autoResize />
        </div>
        <h4 className='h4-style'>
            We usually reply within minutes.
        </h4>
        <div className='contact-item-container'>
            <Button label="Submit" onClick={() => sendMessage()} />
        </div>
    </div>;

    const stepLoading = <div className='contact-loading'>
        <ProgressSpinner />
    </div>;

    const stepDone = <div>
        <h2 className='h2-style'>
            <table>
                <tr>
                    <td>
                        <div className='contact-done-logo'>
                            <i className="pi pi-check" style={{ 'fontSize': '2em' }}></i>
                        </div>
                    </td>
                    <td>
                        Your message was successfully sent. We will get back to you as soon as possible.
                    </td>
                </tr>
            </table>
        </h2>
    </div>

    let content = null;
    switch (step) {
        case 'input':
            content = stepInput;
            break;
        case 'loading':
            content = stepLoading;
            break;
        case 'done':
            content = stepDone;
            break;
        default:
            break;
    }

    return (
        <div className='contact'>
            {content}
        </div>
    );
}

export { ContactUs };