import './ProjectDetails.css';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

import { PageHeader } from '../header/PageHeader';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { TabMenu } from 'primereact/tabmenu';

import ProjectDetailsWarning from './ProjectDetailsWarning';
import ProjectDetailsCta from './ProjectDetailsCta';
import ProjectDetailsDescription from './ProjectDetailsDescription';
import ProjectMember from './ProjectMember';
import ProjectMemberAddDialog from './ProjectMemberAddDialog';
import ProjectDetailsAddTemplateDialog from './ProjectDetailsAddTemplateDialog';
import ProjectDetailsTemplate from './ProjectDetailsTemplate';
import ProjectDetailsDevserver from './ProjectDetailsDevserver';
import ProjectProvisioning from './provisioning/ProjectProvisioning';

import { getHeaders } from '../Services';


function ProjectDetails() {
    const navigate = useNavigate();

    const [projectMetadata, setProjectMetadata] = useState(null);
    const [memberships, setMemberships] = useState([]);
    const [templateEdges, setTemplateEdges] = useState([]);
    const [devserverEdges, setDevserverEdges] = useState([]);

    const [showAddMemberDialog, setShowAddMemberDialog] = useState(false);
    const [showAddTemplate, setShowAddTemplate] = useState(false);
    const [showSettings, setShowSettings] = useState(false);

    const queryString = window.location.search;
    // TODO: make it more beautiful.
    const projectID = queryString.split('=')[1];

    const headers = getHeaders();

    const settingsTabs = [
        {
            label: 'Provisioning Queue',
            icon: 'pi pi-fw pi-home',
        },
        {
            label: 'Delete Project',
            icon: 'pi pi-fw pi-calendar',
        },
    ];
    const [activeIndex, setActiveIndex] = useState(0);

    const getProjectMetadata = () => {
        axios.get(
            `/project/${projectID}`,
            {
                headers: headers
            }
        ).then(
            (response) => {
                setProjectMetadata(response.data);
            }
        ).catch(
            (_error) => { }
        )
    }

    const getMembers = () => {
        axios.get(
            '/projectmembership/',
            {
                headers: headers,
                params:
                {
                    project_id: projectID,
                    usecase: 'project-details'
                }
            }
        ).then(
            (response) => {
                setMemberships(response.data);
            }
        ).catch(
            (_error) => { }
        )
    }

    const getTemplateEdges = () => {
        axios.get(
            '/edgeprojecttodevtemplate/',
            {
                headers: headers,
                params: {
                    project_id: projectID
                },
            }
        ).then(
            (response) => {
                setTemplateEdges(response.data);
            }
        )
    };

    const divTemplateEdges = templateEdges.map(
        te =>
            <ProjectDetailsTemplate
                projectID={projectID}
                data={te}
                reloadData={
                    () => {
                        setTemplateEdges([]);
                        setDevserverEdges([]);
                        getTemplateEdges();
                        getDevserverEdges();
                    }
                }
            />
    );

    const getDevserverEdges = () => {
        axios.get(
            '/edgeprojecttodevserver/',
            {
                headers: headers,
                params: {
                    project_id: projectID
                },
            }
        ).then(
            (response) => {
                setDevserverEdges(response.data);
            }
        ).catch(
            (_error) => { }
        )
    };

    const divDevserverEdges = devserverEdges.map(
        de =>
            <ProjectDetailsDevserver
                data={de}
                reloadData={getDevserverEdges}
            />
    );

    useEffect(
        () => getProjectMetadata(),
        []
    );
    useEffect(
        () => getMembers(),
        []
    );
    useEffect(
        () => getTemplateEdges(),
        []
    );
    useEffect(
        () => getDevserverEdges(),
        []
    );

    if (projectMetadata === null) {
        return (
            <div>
                <PageHeader mode='account' />
            </div>
        );
    }

    const divMemberships = memberships.map(
        member =>
            <ProjectMember
                key={member.id}
                projectID={projectID}
                data={member}
                reloadMembers={() => getMembers()
                }
            />
    );

    const deleteProject = () => {
        axios.delete(
            `/project/${projectID}/`,
            {
                headers: headers
            }
        ).then(
            (_response) => {
                navigate('/projects/');
            }
        )
    }

    return (
        <div>
            <PageHeader mode='account' />
            <div className='project-details' align='center'>
                <h2 style={{ padding: '0.5em', color: 'grey' }}>
                    {projectMetadata.title}
                </h2>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    width: 'max-content',
                    borderRadius: '1em'
                }}
                    align='center'
                >
                    <div className='project-details-tab'>
                        <h4>
                            Project details here
                        </h4>
                        <div>
                            <ProjectDetailsDescription
                                projectID={projectID}
                                description={projectMetadata.description}
                            />
                        </div>
                    </div>
                    <div className='project-details-tab'>
                        <h4>
                            Template Boxes ({templateEdges.length})
                        </h4>
                        <div>
                            {
                                templateEdges.length === 0
                                    ? <ProjectDetailsWarning message='no templates' />
                                    : divTemplateEdges
                            }
                            <ProjectDetailsCta
                                message='add template'
                                onClick={() => setShowAddTemplate(true)}
                            />
                            <Dialog
                                header='Templates'
                                visible={showAddTemplate}
                                onHide={() => setShowAddTemplate(false)}
                            >
                                <ProjectDetailsAddTemplateDialog
                                    projectID={projectID}
                                    close={
                                        () => {
                                            getTemplateEdges();
                                            setShowAddTemplate(false);
                                        }
                                    }
                                />
                            </Dialog>
                        </div>
                    </div>
                    <div className='project-details-tab'>
                        <h4>
                            Dev Boxes ({devserverEdges.length})
                        </h4>
                        {
                            devserverEdges.length === 0
                                ? <ProjectDetailsWarning message='no devboxes yet' />
                                : divDevserverEdges
                        }
                    </div>
                    <div className='project-details-tab'>
                        <h4>
                            Members ({memberships.length})
                        </h4>
                        {divMemberships}
                        <ProjectDetailsCta
                            message='add someone'
                            onClick={() => setShowAddMemberDialog(true)}
                        />
                        <Dialog
                            header='Add member'
                            visible={showAddMemberDialog}
                            onHide={() => setShowAddMemberDialog(false)}
                        >
                            <ProjectMemberAddDialog
                                projectID={projectID}
                                reloadMembers={getMembers}
                            />
                        </Dialog>
                    </div>
                </div>
                <Dialog
                    header='Settings'
                    visible={showSettings}
                    onHide={() => setShowSettings(false)}
                >
                    <div style={{ width: '100em' }}>
                        <TabMenu
                            model={settingsTabs}
                            activeIndex={activeIndex}
                            onTabChange={(e) => setActiveIndex(e.index)}
                        />
                        {activeIndex === 0 ?
                            <ProjectProvisioning
                                project={projectMetadata}
                                getProject={getProjectMetadata}
                            />
                            : null
                        }
                        {activeIndex === 1 ?
                            <div>
                                <h2>
                                    You can permanently delete your project.
                                </h2>
                                <div>
                                    This will delete all data related to this project.
                                </div>
                                <div style={{ margin: '1em' }}>
                                    <Button
                                        label="Delete"
                                        className="p-button-danger p-button-sm"
                                        onClick={deleteProject}
                                    />
                                </div>
                            </div>
                            : null
                        }
                    </div>
                </Dialog>
                <div align='center' style={{ padding: '2em' }}>
                    <Button
                        label='Settings'
                        className='p-button-sm'
                        onClick={() => setShowSettings(true)}
                    />
                </div>
            </div>
        </div >
    );
}

export default ProjectDetails;