import axios from 'axios';
import { useState, useEffect } from 'react';
import { getHeaders } from '../Services';
import ProjectCover from './ProjectCover';
import './ProjectForeignCover.css';

function ProjectsForeignCover({ data }) {
    const headers = getHeaders();
    const [project, setProject] = useState(null);

    const getProject = () => {
        axios.get(
            `/project/${data.project_id}/`,
            {
                headers: headers
            }
        ).then(
            (response) => {
                setProject(response.data);
            }
        )
    }
    useEffect(
        () => getProject(),
        [],
    );

    if (project == null) {
        return <div>
            Loading...
        </div>;
    }

    return (
        <ProjectCover
            id={data.project_id}
            title={project.title}
            description={project.description}
        />
    );
}

export default ProjectsForeignCover;