import './PageHeader.css';

import axios from 'axios';

import { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import Cookies from 'universal-cookie';

import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

function PageHeader({ mode }) {

    const cookies = new Cookies();
    const navigate = useNavigate();

    const [visible, setVisible] = useState(false);
    const [username, setUsername] = useState(null);

    const logout = () => {
        cookies.remove('token');
        cookies.remove('username');
        navigate('/');
    }

    useEffect(
        () => {
            const headers = {
                'Authorization': 'Bearer ' + cookies.get('token')
            }
            axios.get(
                '/user/',
                {
                    headers: headers
                }
            ).then(
                response => {
                    setUsername(response.data.username);
                }
            ).catch(
                _error => {
                }
            )
        }
    );

    return (
        <div>
            <div className='footer'>
                <table width="100%">
                    <tr>
                        <td>
                            <div className='footer-logo' onClick={() => navigate('/home')}>
                                sandgit
                            </div>
                        </td>
                        <td>
                            <div className="col-12 md:col-4">
                                <div className="p-inputgroup">
                                    <InputText placeholder="search" />
                                    <Button icon="pi pi-search" className="p-button-warning" />
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className='footer-user' onClick={() => navigate('/account')}>
                                Logged in as {username}
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div className={mode === 'account' ? 'main-menu' : 'main-menu-comp'}>
                <div className='main-menu-item' onClick={(e) => setVisible(true)}>
                    Menu
                </div>
            </div>
            {mode === 'account' ?
                <div className='main-div'>
                    <Sidebar visible={visible} onHide={() => setVisible(false)}>
                        <div className='menu-item' onClick={() => navigate('/notifications')}>
                            Notifications
                        </div>
                        <div className='menu-item-small' onClick={() => navigate('/notifications')}>
                            All notifications
                        </div>
                        <div className='menu-item' onClick={() => navigate('/projects')}>
                            Projects
                        </div>
                        <div className='menu-item-small' onClick={() => navigate('/projects/new')}>
                            New project
                        </div>
                        <div className='menu-item-small' onClick={() => navigate('/projects')}>
                            My projects
                        </div>
                        <div className='menu-item-small' onClick={() => navigate('/projects/shared')}>
                            Shared projects
                        </div>
                        <div className='menu-item-small' onClick={() => navigate('/projects/invites')}>
                            Invites
                        </div>
                        <div className='menu-item' onClick={() => navigate('/templates')}>
                            Templates
                        </div>
                        <div className='menu-item-small' onClick={() => navigate('/templates')}>
                            Templates store
                        </div>
                        <div className='menu-item-small' onClick={() => navigate('/templates/my')}>
                            My templates
                        </div>
                        <div className='menu-item-small' onClick={() => navigate('/templates/new')}>
                            New template
                        </div>
                        <div className='menu-item' onClick={() => navigate('/home')}>
                            Devservers
                        </div>
                        <div className='menu-item-small' onClick={() => navigate('/home')}>
                            My devservers
                        </div>
                        <div className='menu-item-small' onClick={() => navigate('/devs')}>
                            New devserver
                        </div>

                        <div className='line'></div>

                        <div className='menu-item'>
                            VPN
                        </div>
                        <div className='menu-item-small-disabled'>
                            Setup new VPN server
                        </div>
                        <div className='menu-item-small-disabled'>
                            Generate certificate
                        </div>
                        <div className='menu-item-small-disabled'>
                            Manage clients
                        </div>

                        <div className='line'></div>

                        <div className='menu-item'>
                            Cloud Providers
                        </div>
                        <div className='menu-item-small-disabled'>
                            AWS
                        </div>
                        <div className='menu-item-small-disabled'>
                            Digital Ocean
                        </div>
                        <div className='menu-item-small-disabled'>
                            Sandgit Cloud
                        </div>

                        <div className='line'></div>

                        <div className='menu-item'>
                            Integrations
                        </div>
                        <div className='menu-item-small-disabled'>
                            Git
                        </div>
                        <div className='menu-item-small-disabled'>
                            Bitbucket
                        </div>

                        <div className='line'></div>

                        <div className='menu-item'>
                            Security Logs
                        </div>
                        <div className='menu-item-small-disabled'>
                            Security Dashboard
                        </div>
                        <div className='menu-item-small-disabled'>
                            Alerts
                        </div>

                        <div className='line'></div>

                        <div className='menu-item'>
                            Billing
                        </div>
                        <div className='menu-item-small-disabled'>
                            Invoices
                        </div>
                        <div className='menu-item-small-disabled'>
                            Payment methods
                        </div>

                        <div className='line'></div>

                        <div className='menu-item' onClick={() => { navigate('/account'); }}>
                            Account
                        </div>
                        <div className='menu-item-small-disabled'>
                            Switch to Company View
                        </div>
                        <div className='menu-item-small' onClick={logout}>
                            Logout
                        </div>
                    </Sidebar>
                </div>
                : null
            }
            {mode === 'company' ?
                <div className='main-div'>
                    <Sidebar visible={visible} onHide={() => setVisible(false)}>
                        <div className='menu-item'>
                            Settings
                        </div>
                        <div className='menu-item-small' onClick={() => { navigate('/company/details/'); }} >
                            Company details
                        </div>
                        <div className='menu-item' onClick={() => { navigate('/company/directory/'); }}>
                            Directory
                        </div>
                        <div className='menu-item-small' onClick={() => { navigate('/company/directory/'); }}>
                            Users
                        </div>
                        <div className='menu-item-small-disabled'>
                            Identity Providers (IDP)
                        </div>
                        <div className='menu-item-small-disabled'>
                            Domains
                        </div>
                        <div className='menu-item'>
                            Billing
                        </div>
                        <div className='menu-item-small-disabled'>
                            Invoices
                        </div>
                        <div className='menu-item-small-disabled'>
                            Payment methods
                        </div>
                        <div className='menu-item' onClick={() => { navigate('/account'); }}>
                            Account
                        </div>
                        <div className='menu-item-small' onClick={() => { navigate('/home'); }}>
                            Back to Account View
                        </div>
                        <div className='menu-item-small' onClick={logout}>
                            Logout
                        </div>
                    </Sidebar>
                </div>
                : null
            }
        </div>
    );
}

export { PageHeader };