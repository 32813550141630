import './ProjectCover.css';

import { useNavigate } from 'react-router-dom';

function ProjectCover({ id, title, description }) {
    const navigate = useNavigate();

    return (
        <div
            className='project-cover-main'
            onClick={
                () => navigate({ pathname: '/projects/details', search: `?project_id=${id}` })
            }
        >
            Project:
            <h4>
                {title}
            </h4>
            <div className='cut-text'>
                {description}
            </div>
        </div>
    );
}

export default ProjectCover;