import './TemplatesMy.css';

import axios from 'axios';
import { useState, useEffect } from 'react';
import { PageHeader } from '../header/PageHeader';
import TemplateMyCover from './TemplateMyCover';
import { useNavigate } from 'react-router-dom';

import { Button } from 'primereact/button';
import { getHeaders } from '../Services';
function TemplatesMy() {
    const headers = getHeaders();
    const navigate = useNavigate();

    const [myTemplates, setMyTemplates] = useState([]);

    const getMyTemplates = () => {
        axios.get(
            '/template/',
            {
                headers: headers
            }
        ).then(
            (response) => {
                setMyTemplates(response.data);
            }
        )
    };

    useEffect(
        () => getMyTemplates(),
        []
    );

    if (myTemplates.length == 0) {
        return (
            <div align='center'>
                <PageHeader mode='account' />
                <div style={{ margin: '2em' }} align='center'>
                    You don't have any templates, see store to add one.
                </div>
                <div>
                    <Button
                        label='Store templates'
                        onClick={() => navigate('/templates/')}
                    />
                </div>
            </div>
        );
    }

    const divMyTemplates = myTemplates.map(
        t => <TemplateMyCover data={t} reloadMyTemplates={getMyTemplates} />
    );

    return (
        <div>
            <PageHeader mode='account' />
            <div className='templates'>
                {divMyTemplates}
            </div>
        </div>
    );
}

export default TemplatesMy;