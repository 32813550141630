import './Templates.css';

import 'animate.css';
import axios from 'axios';
import { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import TemplateCover from './TemplateCover';
import { PageHeader } from '../header/PageHeader';

import img_two from './../v2/resources/two.svg';

function Templates() {
    const cookies = new Cookies();
    const [templates, setTemplates] = useState(
        [
            {
                id: 1,
                title: 'React App template',
                services: [
                    'React', 'nginx'
                ],
                host: 'hosted by Sandgit',
                origin: 'default-set1',
            },
            {
                id: 2,
                title: 'Angular App template',
                services: [
                    'Angular', 'nginx'
                ],
                host: 'hosted by Sandgit',
                origin: 'default-set2',
            },
            {
                id: 3,
                title: 'Full web app example template',
                services: [
                    'React', 'Django', 'MySql', 'nginx'
                ],
                host: 'hosted by Sandgit',
                origin: 'default-set3',
            },
        ]
    );

    // TODO: remove this copy-paste
    const [myTemplates, setMyTemplates] = useState([]);
    const getMyTemplates = () => {
        const headers = {
            'Authorization': 'Bearer ' + cookies.get('token')
        }
        axios.get(
            '/template/',
            {
                headers: headers
            }
        ).then(
            (response) => {
                setMyTemplates(response.data);
            }
        )
    };
    useEffect(
        () => getMyTemplates(),
        []
    );

    const check = (origin) => {
        let origins = myTemplates.map(
            t => t.origin
        );

        return origins.includes(origin);
    }

    const templatesDivs = templates.map(
        t => <TemplateCover
            data={t}
            allowCopy={check(t.origin) == false}
        />
    );
    return (
        <div>
            <PageHeader mode='account' />
            <div className='templates'>
                <div className='templates-left'>
                    {templatesDivs}
                </div>
                <div
                    className='templates-right-menu animate__animated animate__slideInRight'
                    align='left'
                >
                    <table>
                        <tr>
                            <td>
                                <div style={{ margin: '2em' }}>
                                    <img src={img_two} width='128'></img>
                                </div>
                            </td>
                            <td>
                                <h1>
                                    Templates store 
                                </h1>
                                <div>
                                    available: {templates.length}
                                </div>
                            </td>
                        </tr>
                    </table>
                    <h2>
                        Ready to go dev box templates
                    </h2>
                    <h3>
                        ideal when starting a new project.
                    </h3>
                </div>
            </div>
        </div>
    );
}

export default Templates;