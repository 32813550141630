import './NotifPage.css';
import axios from 'axios';
import { useState, useEffect } from 'react';

import { PageHeader } from './../../header/PageHeader';
import Notification from './Notification';
import { getHeaders } from '../../Services';

function NotifPage() {
    const headers = getHeaders();
    const [myNotifs, setMyNotifs] = useState([]);

    const getMyNotifs = () => {
        axios.get(
            '/notification/',
            {
                headers: headers
            }
        ).then(
            (response) => {
                setMyNotifs(response.data);
                // TODO: fix this.
                setTimeout(
                    () => getMyNotifs(),
                    5000
                );
            }
        );
    };
    const divMyNotifs = myNotifs.map(
        n =>
            <Notification
                title={n.title}
                content={n.body}
            />
    );

    useEffect(
        () => getMyNotifs(),
        [],
    );

    if (myNotifs.length == 0) {
        return (
            <div>
                <PageHeader mode='account' />
                <div align='center'>
                    You don't have any notifications.
                </div>
            </div>
        );
    }

    return (
        <div>
            <PageHeader mode='account' />
            {divMyNotifs}
        </div>
    );
}

export default NotifPage;