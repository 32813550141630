import './App.css';

import 'animate.css';
import 'primeicons/primeicons.css';

import axios from 'axios';
import { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';

import { ProgressSpinner } from 'primereact/progressspinner';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

import PageHome from './home/PageHome';
import Intern from './intern/Intern';
import PageDevserverCreate from './common/devserver/PageDevserverCreate';
import PageAccount from './common/account/PageAccount';
import V1Marketing from './v1/V1Marketing';

import CompDetails from './company/CompDetails';
import CompDirectory from './company/CompDirectory';
import CompHome from './company/CompHome';
import Demo from './demo/Demo';
import InternCharts from './intern/charts/InternCharts';
import InternJobs from './intern/jobs/InternJobs';
import Marketing from './v2/Marketing';
import NotifPage from './common/notifications/NotifPage';
import ProjectDetails from './projects/ProjectDetails';
import ProjectsForeign from './projects/ProjectsForeign';
import ProjectInvites from './projects/ProjectInvites';
import ProjectNew from './projects/ProjectNew';
import Projects from './projects/Projects';
import SignUpByInvite from './signup/SignUpByInvite';
import Templates from './templates/Templates';
import TemplatesMy from './templates/TemplatesMy';
import TemplateNew from './templates/TemplateNew';

import { logEvent } from './Logger';
import V3Home from './v3/V3Home';
import V3Login from './v3/login/V3Login';
import V3BookDemo from './v3/book_demo/V3BookDemo';
import V3Product from './v3/product/V3Product';
import V3Diff from './v3/diff/V3Diff';
// import V3Project from './v3/product/project/V3Project';


function App() {
  const cookies = new Cookies();
  const [isReady, setIsReady] = useState(true);
  const [gkDjango, setGkDjango] = useState(false);

  useEffect(
    () => {
      logEvent('marketing_load_initial_page');
      const token = cookies.get('token')
      if (token == null) {
        return;
      }

      const headers = {
        'Authorization': 'Bearer ' + token
      };
      const params = {
        'gate': 'gk_django'
      }

      axios.get(
        'gk',
        {
          headers: headers,
          params: params,
        },
      ).then(
        response => {
          setGkDjango(response.data);
          setIsReady(true);
        }
      ).catch(
        (_error) => { }
      )
    },
    []
  );

  if (isReady != true) {
    return (
      <div width='100%'>
        <div align='center' style={{ margin: '256px' }}>
          <ProgressSpinner />
        </div>
      </div>
    )
  }

  return (
    <div width='100%'>
      <div>
        <Router>
          <Routes>
            <Route path="/old" element={<V1Marketing />} />
            <Route path='/home' element={<PageHome />} />
            <Route path='/devs' element={<PageDevserverCreate gkDjango={gkDjango} />} />
            <Route path='/account' element={<PageAccount />} />
            <Route path='/company' element={<CompHome />} />
            <Route path='/company/details' element={<CompDetails />} />
            <Route path='/company/directory' element={<CompDirectory />} />
            <Route path='/signup/invite' element={<SignUpByInvite />} />
            <Route path='/intern' element={<Intern />} />
            <Route path='/intern/charts' element={<InternCharts />} />
            <Route path='/intern/jobs' element={<InternJobs />} />
            <Route path='/demo' element={<Demo />} />
            <Route path='/notifications' element={<NotifPage />} />
            <Route path='/projects' element={<Projects />} />
            <Route path='/projects/new' element={<ProjectNew />} />
            <Route path='/projects/details' element={<ProjectDetails />} />
            <Route path='/projects/invites' element={<ProjectInvites />} />
            <Route path='/projects/shared' element={<ProjectsForeign />} />
            <Route path='/templates' element={<Templates />} />
            <Route path='/templates/my' element={<TemplatesMy />} />
            <Route path='/templates/new' element={<TemplateNew />} />
            {/* <Route path='/' element={<Marketing />} /> */}
            <Route path='/' element={<V3Home />} />
            <Route path='/bookdemo' element={<V3BookDemo />} />
            <Route path='/login' element={<V3Login />} />
            {/* <Route path='/product' element={<V3Product />} /> */}
            <Route path='/product/' element={<V3Product />} />
            {/* <Route path='/product/feed' element={<V3Feed />} /> */}
            {/* <Route path='/product/account' element={<V3Account />} /> */}
            {/* <Route path='/product/logout' element={<V3Logout />} /> */}
            {/* <Route path='/product/project/' element={<V3Project />} /> */}
            <Route path='/diff/' element={<V3Diff />} />
          </Routes>
        </Router >
      </div>
    </div>
  );
}

export default App;
