import './PageAccount.css';

import axios from 'axios';
import Cookies from 'universal-cookie';

import { useState, useEffect } from 'react';

import { PageHeader } from '../../header/PageHeader';


function PageAccount() {

    const cookies = new Cookies();
    const [username, setUsername] = useState(null);

    useEffect(
        () => {
            const headers = {
                'Authorization': 'Bearer ' + cookies.get('token')
            }
            axios.get(
                '/user/',
                {
                    headers: headers
                }
            ).then(
                response => {
                    setUsername(response.data.username);
                }
            ).catch(
                _error => {
                }
            )
        }
    );

    return (
        <div>
            <PageHeader mode='account' />
            <div className='account-main'>
                <div className='dc-title'>
                    Account Details
                </div>
                <div className='dc-item-title'>
                    Primary Email:
                </div>
                <div className='services-item'>
                    {username}
                </div>
                <div className='dc-item-title'>
                    Account Type:
                </div>
                <div className='services-item'>
                    DEMO
                </div>
            </div>
        </div >
    );
}

export default PageAccount;