import { ProgressBar } from 'primereact/progressbar';
import './InternJob.css';

function InternJob({ devserver }) {
    return (
        <div className='job'>
            <span className='job-item'>
                Name: {devserver.name}
            </span>
            <span className='job-item'>
                Status: {devserver.status}
            </span>
            <span className='job-item'>
                Certificate Type: {devserver.certificate_type}
            </span>
            <span className='job-item'>
                Template: {devserver.default_services}
            </span>
            <span className='job-item'>
                URL: {devserver.url}
            </span>
            {
                devserver.status == 'provisioning' ?
                    <span className='job-item'>
                        <ProgressBar mode='indeterminate' />
                    </span>
                    : null
            }
        </div>
    );
}

export default InternJob;