import axios from 'axios';
import { useState, useEffect } from 'react';
import { Devserver } from '../common/devserver/Devserver';
import { getHeaders } from '../Services';

import './ProjectDetailsDevserverDialog.css';


function ProjectDetailsDevserverDialog({
    data, setIsCreating, reloadData
}) {
    const headers = getHeaders();
    const [devserverMetadata, setDevserverMetadata] = useState(null);

    const getDevserverMetadata = () => {
        axios.get(
            `/devserver/${data.devserver_id}/`,
            {
                headers: headers
            }
        ).then(
            (response) => {
                setDevserverMetadata(response.data);
                setIsCreating(response.data.status != 'ready');
            }
        )
    }
    useEffect(
        () => getDevserverMetadata(),
        []
    );

    if (devserverMetadata == null) {
        return (
            <div>
                Loading...
            </div>
        );
    }

    return (
        <div>
            <Devserver
                data={devserverMetadata}
                reloadDevservers={reloadData}
            />
        </div>
    );
}

export default ProjectDetailsDevserverDialog;