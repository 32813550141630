import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import './ProjectMemberAddDialog.css';

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getHeaders } from '../Services';

function ProjectMemberAddDialog({ projectID, reloadMembers }) {
    const headers = getHeaders();
    const navigate = useNavigate();
    const [email, setEmail] = useState(null);
    const [invited, setInvited] = useState([]);

    const invite = () => {
        axios.post(
            '/projectmembership/',
            {
                project_id: projectID,
                email: email,
                status: 'test'
            },
            {
                headers: headers
            }
        ).then(
            (_response) => {
                reloadMembers();
                setInvited([...invited, email]);
            }
        )
    };

    const invitedDiv = invited.map(
        email => <div>
            <b>{email}</b> was successfully invited.
        </div>
    )

    return (
        <div>
            <div style={{ padding: '1em' }}>
                {invitedDiv}
            </div>
            <table>
                <tr>
                    <td>
                        <InputText
                            placeholder='email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </td>
                    <td>
                        <Button
                            label='Invite'
                            onClick={() => invite()}
                            disabled={email == null}
                        />
                    </td>
                </tr>
            </table>
        </div>
    );
}

export default ProjectMemberAddDialog;