import { useNavigate } from 'react-router-dom';

function V3FeedMenu() {
    const navigate = useNavigate();

    return (
        <div className='v3-menu'>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '1em',
                }}
            >
                <div
                    className='v3-menu-logo'
                    onClick={() => navigate('/')}
                >
                    SandGit
                </div>
                <div className='v3-menu-items-container'>
                    <div className='v3-menu-item' onClick={() => navigate('/')}>
                        user@test.com
                    </div>
                </div>
            </div>
        </div>
    );
}

export default V3FeedMenu;