
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getHeaders } from '../Services';

import { Button } from 'primereact/button';
import { PageHeader } from '../header/PageHeader';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import ProjectCover from './ProjectCover';

import './ProjectNew.css';


function ProjectNew() {
    const headers = getHeaders();
    const navigate = useNavigate();

    const [projectTitle, setProjectTitle] = useState(null);
    const [description, setDescription] = useState(null);


    const createProject = (title) => {
        const data = {
            title: title,
            description: description,
        };
        axios.post(
            '/project/',
            data,
            {
                headers: headers
            }
        ).then(
            (_response) => {
                navigate('/projects/');
            }
        ).catch(
            (_error) => { }
        )
    };

    return (
        <div>
            <PageHeader mode='account' />
            <div align='center'>
                <h2>
                    Setup a new project
                </h2>
                <table>
                    <tr>
                        <td>
                            <ProjectCover
                                id={null}
                                title={projectTitle}
                                description={description}
                            />
                        </td>
                        <td>
                            <div>
                                <div>
                                    title:
                                </div>
                                <InputText
                                    placeholder='project title'
                                    value={projectTitle}
                                    onChange={
                                        (e) => setProjectTitle(e.target.value)
                                    }
                                />
                                <div>
                                    description:
                                </div>
                                <InputTextarea
                                    placeholder='project details'
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>
                        </td>
                    </tr>
                </table>
                <div style={{ margin: '1em' }}>
                    <Button
                        label="Create"
                        className="p-button-success"
                        onClick={() => createProject(projectTitle)}
                    />
                </div>
            </div>
        </div>
    );
}

export default ProjectNew;