import './SignUpDialog.css';
import { useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { ProgressSpinner } from 'primereact/progressspinner';
import axios from 'axios';

function SignUpDialog({ closeFunction }) {

    const [step, setStep] = useState('input-data')

    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);

    function createAccount() {
        setStep('pending');
        if (username == null || password == null) {
            setStep('fail');
            return;
        }

        const data = {
            'username': username,
            'email': username,
            'password': password
        };

        axios.post('/user/', data).then(
            _response => {
                setStep('success');
            }
        ).catch(
            _error => {
                setStep('fail');
            }
        )
    }

    const createAccountIfEnter = (e) => {
        if (e.key != 'Enter') {
            return;
        }
        createAccount();
    }

    return (
        <div>
            {step == 'input-data' ?
                <div>
                    <div style={{ padding: '32px' }}>
                        Enter here your email and setup a password to continue.
                    </div>
                    <div className='div-inputs'>
                        <div className='login-input' onKeyDown={createAccountIfEnter}>
                            <InputText value={null} onChange={(e) => setUsername(e.target.value)} placeholder="email" />
                        </div>
                        <div className='login-input' onKeyDown={createAccountIfEnter}>
                            <Password value={null} onChange={(e) => setPassword(e.target.value)} placeholder="password" />
                        </div>
                    </div>
                    <div className='div-create-button'>
                        <Button label="Create Account" className="p-button-success" onClick={createAccount} />
                    </div>
                </div>
                : null
            }
            {step == 'pending' ?
                <div>
                    Your acount is being created...
                    <div className='div-spinner'>
                        <ProgressSpinner />
                    </div>
                </div>
                : null
            }
            {step == 'success' ?
                <div>
                    <div classname='div-success-content'>
                        Your account was successfully created!
                    </div>
                    <div className='div-success-button'>
                        <Button label="Ok" onClick={closeFunction} />
                    </div>
                </div>
                : null
            }
            {step == 'fail' ?
                <div>
                    <div className='div-error-content'>
                        Something went wrong when creating your account.
                    </div>
                    <div className='div-error-button'>
                        <Button label="Back" onClick={() => setStep('input-data')} />
                    </div>
                </div>
                : null
            }
        </div>
    )
}

export { SignUpDialog }