import { useEffect, useState } from "react";
import { getDevserverEdges } from "../../services/ServiceDevserverEdge.ts";
import V3ProjectDevserver from "./V3ProjectDevserver";

function V3ProjectDevservers({ projectID }) {
    const [devEdges, setDevEdges] = useState([]);

    const loadDevserverEdges = () => {
        getDevserverEdges(projectID).then(
            (response) => setDevEdges(response.data),
        );
    };
    useEffect(
        () => {
            loadDevserverEdges();
        },
        [projectID],
    )

    const divDevservers = devEdges.map(
        dev_edge =>
            <V3ProjectDevserver
                devEdge={dev_edge}
                loadDevserverEdges={loadDevserverEdges}
            />
    );

    return (
        <div>
            {divDevservers}
        </div>
    );
}

export default V3ProjectDevservers;