import './Devserver.css';

import { useEffect, useState, useRef } from 'react';

import sshextension from './../../v2/resources/ssh-extension.png';
import step2 from './../../v2/resources/step2.png';
import step3 from './../../v2/resources/step3.png';
import step4 from './../../v2/resources/step4.png';
import step5 from './../../v2/resources/step5.png';
import step6 from './../../v2/resources/step6.png';

import { Button } from 'primereact/button';
import { SplitButton } from 'primereact/splitbutton';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { ProgressBar } from 'primereact/progressbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { TabView, TabPanel } from 'primereact/tabview';
import 'primeicons/primeicons.css';

import axios from 'axios';
import { getHeaders } from '../../Services';

function Devserver({ data, reloadDevservers }) {
    const headers = getHeaders()
    const [activeIndex, setActiveIndex] = useState(0);
    const [showHowToConnect, setShowHowToConnect] = useState(false)
    const [my_data, setMyData] = useState(data);
    const [isUITrusted, setIsUITrusted] = useState(false);
    const [isAPITrusted, setIsAPITrusted] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);

    const checkCertificates = () => {
        axios.get('https://' + data.url).then(
            (_response) => {
                setIsUITrusted(true);
            }
        ).catch(
            (_error) => {
                setTimeout(
                    () => {
                        setIsUITrusted(false);
                        checkCertificates();
                    },
                    5000
                )
            }
        )
    }

    const checkCertificatesAPI = () => {
        axios.get('https://api.' + data.url).then(
            (_response) => {
                setIsAPITrusted(true);
            }
        ).catch(
            error => {
                setTimeout(
                    () => {
                        setIsAPITrusted(false);
                        checkCertificatesAPI();
                    },
                    5000
                )
            }
        )
    }

    const deleteDevserver = () => {
        axios.delete(
            `/devserver/${data.id}/`,
            {
                headers: headers
            }
        ).then(
            (_response) => {
                setIsDeleted(true);
                reloadDevservers();
            }
        )
    }

    const manage_button_items = [
        {
            label: 'Delete',
            icon: 'pi pi-times',
            command: () => {
                deleteDevserver();
            }
        },
    ];

    const reloadData = () => {
        axios.get(
            '/devserver/' + data['id']
        ).then(
            response => {
                setTimeout(
                    () => {
                        setMyData(response.data)
                        if (response.data['status'] != 'ready') {
                            reloadData();
                        }
                    },
                    5000
                )
            }
        ).catch(
            (_error) => { }
        )
    }

    useEffect(
        () => {
            if (my_data.status == 'ready') {
                return;
            }
            reloadData();
        },
        []
    );

    useEffect(
        () => {
            if (isUITrusted) {
                return;
            }
            checkCertificates();
        },
        []
    );

    useEffect(
        () => {
            if (isAPITrusted) {
                return;
            }
            checkCertificatesAPI();
        },
        []
    )

    if (isDeleted) {

        return (
            <div className='devserver' style={{ padding: '2em' }}>
                Server was successfully deleted.
            </div>
        );
    }

    return (
        <div className='devserver'>

            {my_data.status != 'ready' ?
                <div className='create-not-ready'>
                    <div className='title'>{my_data.name}</div>
                    <div className='create-now'>
                        Your devserver is being created right now...
                    </div>
                    <div>
                        {my_data.status == 'creating' ?
                            <div className='setup-tasks'>
                                <div>
                                    <table>
                                        <tr>
                                            <td>
                                                reserving machine
                                            </td>
                                            <td>
                                                <ProgressSpinner style={{ width: '32px', height: '32px' }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                setup network
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                setup authentication
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div> : null
                        }
                        {my_data.status == 'setup-network' ?
                            <div className='setup-tasks'>
                                <div>
                                    <table>
                                        <tr>
                                            <td>
                                                <b>reserve machine - OK</b>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                setting up network
                                            </td>
                                            <td>
                                                <ProgressSpinner style={{ width: '32px', height: '32px' }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                setup authentication
                                            </td>
                                            <td></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            : null
                        }
                        {my_data.status == 'setup-auth' ?
                            <div className='setup-tasks'>
                                <div>
                                    <table>
                                        <tr>
                                            <td>
                                                <b>reserve machine - OK</b>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <b>setup network - OK</b>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                setting up authentication
                                            </td>
                                            <td>
                                                <ProgressSpinner style={{ width: '32px', height: '32px' }} />
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            : null
                        }
                    </div>
                    <div className='creating-spinner-div'>
                        <ProgressBar mode="indeterminate" />
                    </div>
                </div>
                : null
            }
            {my_data.status == 'ready' ?
                <div>
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <i className="pi pi-server" style={{ fontSize: '2em' }}></i>
                            <div className='title'>{my_data.name}</div>
                        </div>
                        <div>
                            Your devserver is <span className='devserver-ready'>ready</span>.
                            Droplet ID: {my_data.droplet_id}. See all details below.
                        </div>
                        <table width='100%'>
                            <tr>
                                <td>
                                    <div className='services'>
                                        <div className='services-list'>
                                            <ul>
                                                <li>
                                                    URL:&nbsp;
                                                    <ul>
                                                        <li>
                                                            <a href={"https://" + my_data.url} target="_blank">
                                                                <b>{my_data.url}</b>
                                                            </a>
                                                        </li>
                                                        {isUITrusted == false ?
                                                            <div>
                                                                <table>
                                                                    <tr>
                                                                        <td>
                                                                            <div style={{ margin: '4px', color: 'red' }}>
                                                                                <span style={{ padding: '4px' }}>
                                                                                    <i className="pi pi-exclamation-circle"></i>
                                                                                </span>
                                                                                Dev Certificate, not CA-Signed, CORS
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <a href={"https://" + my_data.url} target='_blank'>
                                                                                <div className='ca-manually-trust'>
                                                                                    (Manually Trust)
                                                                                </div>
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                            : null
                                                        }
                                                    </ul>
                                                </li>
                                                {my_data.url_api != '' && my_data.url_api != null ?
                                                    <li>
                                                        API:&nbsp;
                                                        <ul>
                                                            <li>
                                                                <a href={"https://" + my_data.url_api} target="_blank">
                                                                    <b>{my_data.url_api}</b>
                                                                </a>
                                                            </li>
                                                            {isAPITrusted == false ?
                                                                <div>
                                                                    <table>
                                                                        <tr>
                                                                            <td>
                                                                                <div style={{ margin: '4px', color: 'red' }}>
                                                                                    <span style={{ padding: '4px' }}>
                                                                                        <i className="pi pi-exclamation-circle"></i>
                                                                                    </span>
                                                                                    Dev Certificate, not CA-Signed, CORS
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <a href={"https://api." + my_data.url} target='_blank'>
                                                                                    <div className='ca-manually-trust'>
                                                                                        (Manually Trust)
                                                                                    </div>
                                                                                </a>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                                : null
                                                            }
                                                        </ul>
                                                    </li>
                                                    : null
                                                }
                                                <li>IPv4:
                                                    <ul>
                                                        <li>
                                                            <a href={'https://' + my_data.droplet_ipv4} target='_blank'>
                                                                {my_data.droplet_ipv4}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className='buttons'>
                        <table align='center'>
                            <tr>
                                <td>
                                    <SplitButton
                                        label="Manage"
                                        model={manage_button_items}
                                        className="p-button-secondary p-button-sm mr-2 mb-2"
                                    />
                                </td>
                                <td>
                                    <Button
                                        className='p-button-sm'
                                        label="How to connect"
                                        onClick={() => setShowHowToConnect(true)}
                                    />
                                    <Dialog
                                        header="How to connect"
                                        visible={showHowToConnect}
                                        onHide={() => setShowHowToConnect(false)}
                                    >
                                        <div className='create-howto'>
                                            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                                                <TabPanel header="Visual Code">
                                                    <div>
                                                        <div className='create-howto-step-title'>
                                                            Install SSH extension for vscode
                                                        </div>
                                                        <div className='create-howto-image-div'>
                                                            <div>
                                                                <Image src={sshextension} width={512} />
                                                            </div>
                                                            <div>
                                                                <Image src={step2} width={512} />
                                                            </div>
                                                        </div>
                                                        <div className='create-howto-step-title'>
                                                            Edit the SSH Config file
                                                        </div>
                                                        <div className='create-howto-image-div'>
                                                            <div>
                                                                <Image src={step3} width={512} />
                                                            </div>
                                                            <div>
                                                                <Image src={step4} width={512} />
                                                            </div>
                                                        </div>
                                                        <div className='create-howto-step-title'>
                                                            Connect
                                                        </div>
                                                        <div className='create-howto-image-div'>
                                                            <div>
                                                                <Image src={step5} width={512} />
                                                            </div>
                                                            <div>
                                                                <Image src={step6} width={512} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel header="Eclipse" disabled={true}></TabPanel>
                                                <TabPanel header="vim" disabled={true}></TabPanel>
                                            </TabView>
                                        </div>
                                    </Dialog>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div >
                : null
            }
        </div >)
}

export { Devserver }