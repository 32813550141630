import './V3Feed.css';
import './../project_menu/V3ProjectMenu.css';

import V3HomeMenu from '../home_menu/V3HomeMenu';
import V3Feed from './V3Feed';
import V3HomeNotifications from '../home_notifications/V3HomeNotifications';
import V3ProjectNew from '../project_new/V3ProjectNew';
import { useState } from 'react';

function V3Home({
    loadProjects,
    projectMemberships,
    loadProjectMemberships,
}) {
    const [selectedItem, setSelectedItem] = useState('feed');

    return (
        <div>
            <div className='v3-feed-main-item-center'>
                <V3HomeMenu
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                    notificationsCount={projectMemberships.length}
                />
            </div>
            {selectedItem == 'feed'
                ? <V3Feed />
                : null
            }
            {
                selectedItem == 'notifications'
                    ? <V3HomeNotifications
                        loadProjects={loadProjects}
                        projectMemberships={projectMemberships}
                        loadProjectMemberships={loadProjectMemberships}
                    />
                    : null
            }
            {
                selectedItem == 'new_project'
                    ? <V3ProjectNew loadProjects={loadProjects} />
                    : null
            }
        </div>
    );
}

export default V3Home;