import './InternChartLastSignups.css';

import { useEffect, useState } from 'react';
import { Chart } from 'primereact/chart';
import axios from 'axios';

function InternChartLastSignups() {
    const [users, setUsers] = useState([]);

    const getUsers = () => {
        axios.get(
            '/intern/',
            {
                params: {
                    dataset: 'last_signups',
                }
            }
        ).then(
            (response) => {
                setUsers(response.data);
            }
        )
    }

    const divUsers = users.map(
        user => <div>
            {user.username}
        </div>
    );
    useEffect(
        () => getUsers(),
        [],
    );

    return (
        <div className="chart1">
            <h5>Last signed up users</h5>
            {divUsers}
        </div>
    );
}

export default InternChartLastSignups;