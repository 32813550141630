import './InternObject.css';

import { useNavigate } from 'react-router-dom';

function InternObject({ title, description, link }) {
    const navigate = useNavigate();

    return (
        <div className='intern-main'>
            <div
                className='intern-object'
                onClick={() => navigate(link)}
            >
                <h2>
                    {title}
                </h2>
                <div>
                    {description}
                </div>
            </div>
        </div>
    )
}

export default InternObject;