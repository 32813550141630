import './InternChartBase.css';

import { useEffect, useState } from 'react';
import { Chart } from 'primereact/chart';
import axios from 'axios';

function InternChartBase({ title, dataset, type }) {
    let basicOptions = {
        maintainAspectRatio: false,
        aspectRatio: .8,
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };
    const [data, setData] = useState({
        labels: [],
        datasets: [
            {
                label: '',
                data: []
            },
        ]
    });

    const getData = () => {
        axios.get(
            '/intern',
            {
                params: {
                    dataset: dataset
                }
            }
        ).then(
            (response) => {
                let data = response.data;
                data['backgroundColor'] = '#42A5F5';
                setData(data);
            }
        )
    };

    useEffect(
        () => {
            getData();
        },
        []
    );
    return (
        <div className="chart1">
            <h5>{title}</h5>
            <Chart
                type={type}
                data={data}
                options={basicOptions}
            />
        </div>
    );
}

export default InternChartBase;