import './PageHome.css'

import axios from 'axios';

import Cookies from 'universal-cookie';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PageMyDevservers } from '../common/devserver/PageMyDevservers';
import { PageHeader } from '../header/PageHeader';

function PageHome() {

    const [username, setUsername] = useState(null);
    const [userID, setUserID] = useState(null);

    useEffect(
        () => {
            const headers = {
                'Authorization': 'Bearer ' + cookies.get('token')
            }
            axios.get(
                '/user/',
                {
                    headers: headers
                }
            ).then(
                response => {
                    setUsername(response.data.username);
                    setUserID(response.data.user_id);
                    cookies.set('username', response.data.username);
                }
            ).catch(
                _error => {
                }
            )
        }
    );

    const logout = () => {
        cookies.remove('token');
        navigate('/');
    }

    const navigate = useNavigate();
    const cookies = new Cookies();
    const token = cookies.get('token');

    return (
        <div>
            <PageHeader mode='account' />
            <PageMyDevservers />
        </div >
    );
}



export default PageHome;