import axios from 'axios';
import { Button } from 'primereact/button';
import { getHeaders } from '../Services';
import './ProjectMembershipDialog.css';


function ProjectMembershipDialog({ projectID, data, close }) {
    const headers = getHeaders();

    const revokeMembership = () => {
        axios.delete(
            `/projectmembership/${data.id}/`,
            {
                headers: headers
            }
        ).then(
            (_response) => { close() }
        )
    };
    return (
        <div className='project-membership-dialog'>
            <div>
                <div style={{ padding: '1em' }}>
                    <i className="pi pi-user" style={{ fontSize: '2em' }} />
                </div>
                <div style={{ padding: '0.2em' }}>
                    <b>{data.email}</b>
                </div>
                <div style={{ color: 'grey', padding: '0.2em' }}>
                    <i>({data.status})</i>
                </div>
            </div>
            <div style={{ padding: '1em' }}>
                <Button
                    label="Revoke invite"
                    className="p-button-danger p-button-sm"
                    onClick={() => revokeMembership()}
                />
            </div>
        </div>
    );
}

export default ProjectMembershipDialog;