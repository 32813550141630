import './ProjectDetailsDevserver.css';

import axios from 'axios';
import { useState, useEffect } from 'react';

import { Dialog } from 'primereact/dialog';
import ProjectDetailsDevserverDialog from './ProjectDetailsDevserverDialog';
import { getHeaders } from '../Services';

function ProjectDetailsDevserver(
    { data, reloadData }
) {
    const headers = getHeaders();

    const [showDevserverDialog, setShowDevserverDialog] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [devserverData, setDevserverData] = useState(null);

    const getDevserverData = () => {
        axios.get(
            `/devserver/${data.devserver_id}/`,
            {
                headers: headers
            }
        ).then(
            (response) => {
                setDevserverData(response.data);
                if (response.data.status != 'ready') {
                    setIsCreating(true);
                    setTimeout(
                        () => getDevserverData(),
                        5000,
                    )
                }
            }
        )
    };

    useEffect(
        () => getDevserverData(),
        []
    );

    if (devserverData == null) {
        return <div>
            Loading..
        </div>
    };
    if (devserverData.status != 'ready') {
        return null;
    }

    return (
        <div>
            <div
                className='pdd'
                onClick={() => setShowDevserverDialog(true)}
                align='left'
            >
                <table>
                    <tr>
                        <td>
                            {
                                isCreating === false
                                    ? <i className="pi pi-server"></i>
                                    : <i className="pi pi-spin pi-spinner" style={{ 'fontSize': '1em' }}></i>
                            }
                        </td>
                        <td>
                            {devserverData.name}
                        </td>
                    </tr>
                </table>
            </div>
            <Dialog
                header="devserver"
                onHide={() => setShowDevserverDialog(false)}
                visible={showDevserverDialog}
            >
                <ProjectDetailsDevserverDialog
                    data={data}
                    setIsCreating={(v) => setIsCreating(v)}
                    reloadData={reloadData}
                />
            </Dialog>

        </div>
    );
}

export default ProjectDetailsDevserver;