import './InternChartAllEvents.css';
import InternChartBase from './InternChartBase';

function InternChartAllEvents() {
    return (
        <InternChartBase
            title='All Events (total count)'
            dataset='all_events'
            type='line'
        />
    );
}

export default InternChartAllEvents;