import { useNavigate } from "react-router-dom";
import V3Button from "../button/V3Button";

function V3Logout() {
    const navigate = useNavigate();

    return (
        <div className='v3-feed-main-item-center'>
            <h2>Are you sure you want to logout now?</h2>
            <div>
                <V3Button
                    label='LOGOUT'
                    option='danger'
                    onClick={() => navigate('/')}
                />
            </div>
        </div>
    );
}

export default V3Logout;