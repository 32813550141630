import './ProjectDetailsTemplate.css';

import axios from 'axios';
import { useState, useEffect } from 'react';

import { ProgressSpinner } from 'primereact/progressspinner';
import { Dialog } from 'primereact/dialog';
import ProjectDetailsTemplateDialog from './ProjectDetailsTemplateDialog';
import { getHeaders } from '../Services';

function ProjectDetailsTemplate({ projectID, data, reloadData }) {
    const headers = getHeaders();
    const [template, setTemplate] = useState(null);
    const [showTemplateDialog, setShowTemplateDialog] = useState(false);

    const getTemplate = () => {
        axios.get(
            `/template/${data.dev_template_id}/`,
            {
                headers: headers
            }
        ).then(
            (response) => setTemplate(response.data)
        );
    };

    useEffect(
        () => getTemplate(),
        []
    );

    if (template == null) {
        return (
            <div className='pd-template'>
                <table>
                    <tr>
                        <td>
                            <ProgressSpinner
                                style={{ width: '1em', height: '1em' }}
                            />
                        </td>
                        <td>
                            loading...
                        </td>
                    </tr>
                </table>
            </div>
        );
    }

    return (
        <div>
            <div
                className='pd-template'
                onClick={() => setShowTemplateDialog(true)}
            >
                <table>
                    <tr>
                        <td>
                            <i className="pi pi-server" />
                        </td>
                        <td>
                            {template.title}
                        </td>
                    </tr>
                </table>
            </div>
            <Dialog
                header='Template Box'
                visible={showTemplateDialog}
                onHide={
                    () => {
                        setShowTemplateDialog(false);
                        reloadData();
                    }
                }
            >
                <ProjectDetailsTemplateDialog
                    projectID={projectID}
                    edge_data={data}
                    template_data={template}
                    reloadData={reloadData}
                />
            </Dialog>
        </div>
    );
}

export default ProjectDetailsTemplate;