import V3Button from '../button/V3Button';
import './../feed/V3Feed.css';

function V3Account() {
    return (
        <div>
            <div className='v3-feed-main-item-center'>
                <h2>
                    My Account Details
                </h2>
                <div style={{ marginTop: '1em' }}>
                    status: active
                </div>
                <div style={{ marginTop: '1em' }}>
                    type: demo
                </div>
            </div>
            <div className='v3-feed-main-item-center'>
                <h2>
                    Upgrade
                </h2>
                <div style={{ marginTop: '1em' }}>
                    Your current account is a DEMO account.
                </div>
                <div style={{ marginTop: '1em' }}>
                    <V3Button
                        label='UPGRADE'
                        disabled={true}
                    />
                </div>
            </div>
            <div className='v3-feed-main-item-center'>
                <h2>
                    Data deletion
                </h2>
                <div>
                    You can always delete your account and all data associated with.
                    For demo accounts, please contact our support team at support@sandgit.com
                </div>
                <div>
                    <V3Button
                        label='DELETE MY ACCOUNT'
                        option='danger'
                        disabled={true}
                    />
                </div>
            </div>
        </div>
    );
}

export default V3Account;