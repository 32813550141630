import './../App.css';

import img_time from './../v2/resources/complex.jpg';
import img_complex from './../v2/resources/complex.jpg';
import img_leak from './../v2/resources/leak.jpg';
import img_bad from './../v2/resources/bad.jpg';
import img_wall from './../v2/resources/wall.jpg';
import img_qa from './../v2/resources/qa.jpg';
import img_bye from './../v2/resources/bye.jpg';
import img_repetitive from './../v2/resources/repetitive.jpg';
import img_security from './../v2/resources/security.jpg';
import img_broken from './../v2/resources/broken.jpg';
import img_storm from './../v2/resources/storm.jpg';

import 'primeicons/primeicons.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import Cookies from 'universal-cookie';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';

import { SignUpDialog } from './../signup/SignUpDialog';

import { logEvent } from './../Logger';

import { Benefit } from './Benefit';
import { ContactUs } from './ContactUs';
import { Panel } from 'primereact/panel';

import 'animate.css';
import { BenefitLarge } from './BenefitLarge';

function V1Marketing() {

  const [showD, setShowD] = useState(false);
  const [step, setStep] = useState('login-input');
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  const onClick = () => {
    logEvent('click_create_demo_account');
    setShowD(true);
  }

  const onClose = () => {
    setShowD(false)
  }

  const login = () => {
    setStep('login-pending');
    if (username == null || password == null) {
      setStep('login-error');
      return;
    }

    const data = {
      username: username,
      password: password
    }

    axios.post('/api/token/', data).then(
      response => {
        const token = response.data.access;
        cookies.set('token', token);
        navigate('/home');
      }
    ).catch(
      _error => {
        setStep('login-error');
      }
    )
  }

  const loginIfEnter = (e) => {
    if (e.key != 'Enter') {
      return;
    }
    login();
  }

  const navigate = useNavigate();
  const cookies = new Cookies();

  return (
    <div>
      <div className="marketing-up" id='home'>
        <table className='marketing-table'>
          <tr>
            <td>
              <div className='marketing-logo'>
                <table>
                  <tr>
                    <td>
                      <div className='main-title'>
                        <div className='logo animate__animated animate__bounce'>sandgit hello</div>
                      </div>
                      <div className='sub-title animate__animated animate__zoomInRight'>
                        <b>dev-servers</b>: easy, remote and secure dsadsads
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </td>
            <td align='right'>
              <div className='login-div animate__animated animate__zoomInRight'>
                {step == 'login-input' ?
                  <div>
                    <table>
                      <tr>
                        <td>
                          <div className='login-input'>
                            <InputText value={null} onChange={(e) => setUsername(e.target.value)} onKeyDown={loginIfEnter} placeholder="email" />
                          </div>
                        </td>
                        <td>
                          <div className='login-input' onKeyDown={loginIfEnter}>
                            <Password value={null} onChange={(e) => setPassword(e.target.value)} placeholder="password" />
                          </div>
                        </td>
                        <td>
                          <div className="b1">
                            <Button label="Login" onClick={login} />
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                  : null
                }
                {step == 'login-pending' ?
                  <div>
                    <div class="login-pending-spinner">
                      <ProgressSpinner />
                    </div>
                    <div>
                      Authenticating...
                    </div>
                  </div>
                  : null
                }
                {step == 'login-error' ?
                  <div>
                    <div className="login-error-content">
                      Unable to login.
                    </div>
                    <div className="login-error-button">
                      <Button label="Back" onClick={() => { setStep('login-input') }} />
                    </div>
                  </div>
                  : null
                }
              </div>
            </td>
          </tr>
        </table>
        <div className="b1">
          <Button label="Create Demo Account" className="p-button-success cr" onClick={() => onClick()} />
          <Dialog header="Sign Up" visible={showD} onHide={() => setShowD(false)}>
            <SignUpDialog closeFunction={onClose}></SignUpDialog>
          </Dialog>
        </div>
        <div align='center' className='learn-more-button'>
          <table>
            <tr>
              <td>
                <a href='#why'>
                  <Button label="Why dev servers" className="p-button-secondary" />
                </a>
              </td>
              <td>
                <a href='#contact-us'>
                  <Button label="Contact us" className="p-button-secondary" />
                </a>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div align='center' id='why'>
        <table>
          <tr>
            <td>
              <Benefit
                img_src={img_storm}
                title='Zero time to create a new dev box'
                content='Just replcate an existing dev server. Takes few seconds.'
              />
            </td>
            <td>
              <Benefit
                img_src={img_time}
                title='Save precious development time'
                content='Your dev environment is ready to go any time. No need to setup again for each new member.'
              />
            </td>
            <td>
              <Benefit
                img_src={img_repetitive}
                title='Avoid annoying and repetitive tasks'
                content='When someone new joins your team, there is no need to spend bunch of time on setting up the dev env.'
              />
            </td>
            <td>
              <Benefit
                img_src={img_complex}
                title='Complex dev setups'
                content='Just clone an existing box with everything set up properly, rather than installing it by yourself.'
              />
            </td>
          </tr>
          <tr>
            <td>
              <Benefit
                img_src={img_broken}
                title='Broken dev setup'
                content='Just clone the template dev. No waste of time, no stressful tasks.'
              />
            </td>
            <td>
              <Benefit
                img_src={img_security}
                title='Security updates'
                content='Just push them on the dev template. No need to remind each team member to update.'
              />
            </td>
            <td>
              <Benefit
                img_src={img_leak}
                title='Risk to leak code'
                content='Never have to clone the code on a laptop, personal computer or any physical machine.'
              />
            </td>
            <td>
              <Benefit
                img_src={img_bye}
                title='Leaving developer'
                content='No need to clean up their computer or make sure code was permanently deleted. Just revoke remote access.'
              />
            </td>
          </tr>
          <tr>
            <td>
              <Benefit
                img_src={img_qa}
                title='QA needs to test'
                content='Extremely simply: just clone them a box with everything inside. No need to manually set up. Save time for everyone!'
              />
            </td>
            <td>
              <Benefit
                img_src={img_wall}
                title='Staging environments'
                content='Need them? As easy as it sounds: just clone a new box. No need to do anything else.'
              />
            </td>
            <td>
              <Benefit
                img_src={img_bad}
                title='Bad actors'
                content='Alarms. Logs. Evidence. Just disconnect the bad actor at any small signal to minimize the breach. This can also happen automatically.'
              />
            </td>
          </tr>
        </table>
      </div>
      <BenefitLarge
        img_src={img_storm}
        title='Zero time to create a new dev box'
        body='
          There are multiple scenarios when you may need a new dev environment:
          someone new joined the team, the QA wants to test it, you want your team
          and/or your closed group to dogfood the product, you want to prototype or
          to experiment new features, etc. Setting up a new dev environment
          usually requires time and knowledge. Using sandgit, you can just clone an
          existing one with no additional effort.
        '
      />
      <BenefitLarge
        img_src={img_time}
        title='Save precious development time'
        body='
          When setting up a new dev environment, there is not faster than already 
          having one ready to go. That is the real value on the remote development,
          along with all the security features, which eventually will again save some
          time for you. It is about confort, confidence and time.
        '
        quote='
          A game changer in our development. No need for pair-programming or screen
          share with my peers to help them setup the project. It reduces the setup time
          to basically zero.
        '
      />
      <BenefitLarge
        img_src={img_repetitive}
        title='Avoid annoying and repetitive tasks'
        body='
          Setting up the dev environment is something that may not always be an
          enjoyable task to work on. It ofter requires some degree of devops knowledge,
          as long as some historical insights too - and this can be quite difficult,
          specially when you are new on a team or time consuming when you need to help
          other ramp up. More interestingly, there might be areas of your project that not
          all developers are interested to deep dive into them and understand how to install
          them, but they are required for a good end-to-end functionallity. Simply, just
          clone an existing and working dev setup.
        '
      />
      <BenefitLarge
        img_src={img_complex}
        title='Complex dev setups'
        body='
          These days, most of the projects have bunch of integrations and
          increasing complexity. Talking about web projects, there is a good
          chance we have a frontend with bunch of specific libraries to make
          the UI/UX pretty and responsive, will surely have a backend mostly
          responsable for the main API service, but good chance to have other
          things one jobs, cron jobs, async processing, databases, etc. If we
          want out app to have things like "Login with Google" then additional
          integrations will be required. Being able to develop end-to-end
          requires to have the entire dev setup ready, which usually takes
          a lot of time and serious knowlege. Interestingly, most of the time
          you may not really be interested to deepdive into how a specific
          integration works, rather than just being able to play with the whole
          end-to-end locally and focus on the feature you want to develop only.
        '
      />
      <BenefitLarge
        img_src={img_broken}
        title='Broken dev setup'
        body='
          It can happen to anyone. Dev setups can break and sometimes fixing them
          requires more resources than setting up a new one from scratch. What about
          not having any of these problems? If your dev setup is broken, you can just
          wipe it out and clone a new one again. No manual input is required, as easy
          as it sounds, one click away.
        '
      />
      <BenefitLarge
        img_src={img_bye}
        title='Leaving developer'
        body='
          In this new world of remote development, engineers moving to new
          projects is quite a common thing. Also, companies finding new
          collaborators is same usual. When a developer leaves, there is always
          some work to be done to ensure everything is wrapped up properly:
          access on all internal tools is revoked, making sure anything locally
          is being removed - which is quite a difficult thing considering that
          company may not own the remote computer or considering things like
          data recovery. Who is responsable for all these things? It is probably
          easier to just avoid all these problems rather than dealing with bunch
          of custom solutions.
        '
      />
      <BenefitLarge
        img_src={img_qa}
        title='QA needs to test'
        body='
          Not all the tests can be easily automated and sometimes for that extra mile
          of finess you may want to be able to easily manually test your new features.
          That usually requires setting up a box for your QA in order to be able to play
          with - a different one than the current production - which involves setting up
          all the stack, services, integrations, databases, etc. your product is using,
          which as you probably guessed requires time and devops knowledge. Sandgit clones
          your environment as many times as you need without any additional cost.
        '
      />
      <BenefitLarge
        img_src={img_wall}
        title='Staging environments'
        body='
          You may have continous deployment. Or you may release at scheduled dates
          when the product is life critical. In all these cases, a staging environment
          is a gold mine. Your internal people can play with, dogfood it, etc. You can
          also run push-blocking tests, etc. And you get them at no additional work, just
          by clicking the clone button again.
        '
      />
      <BenefitLarge
        img_src={img_bad}
        title='Bad actors'
        body='
          Most of the times, people have positive intentions, however better safe
          than sorry. When the source code is cloned locally, the company has no
          way to limit the harm that a bad indented actor can do. In a remote
          devserver world, the code will never be on the local disk, limiting as much
          as possible any exposure. If compromised, the remote access can be revoked
          immediately without much harm - you can also setup automatic alarms and
          triggers to immediately auto-shutdown any access when detecting things like
          unusually high download bandwith.
        '
        quote='
          "We had an unhappy freelancer that has decided to opensource all our codebase.
          Such a nightmare can risk your business, your partners and generally others
          not willing to do business with you anymore."
        '
      />
      <div className='marketing-vms-container'>
        <table>
          <tr>
            <td width='50%'>
              <div align='center' id='vms' className='marketing-vms'>
                <Panel header="Virtual machines vs. sandgit? What's the difference?" align='center'>
                  <p>Classical virtual machines are usually based on screen share which requires a super-good and continous internet connection. But mostly developer's experience will be questionable when having to write code on a screen share. Sandgit allows you to use any IDE / code editor you preffer and write the required code locally and still benefit for the entire remote experience: setup everything remotely, on a box, rather than locally.</p>
                </Panel>
              </div>
            </td>
            <td width='50%'>
              <div align='center' id='vms' className='marketing-vms'>
                <Panel header="High-speed internet connection, is that required?" align='center'>
                  <p>No. Any internet connection would get the work done without affecting developer's experience at all. Bear in mind that sandgit exchanges small tests of data between your favorite IDE and the devserver and not an entire screen share. These connections are always secured, including VPNs too if required - and they come for free as a basic service on sandgit, no need for you to setup anything.</p>
                </Panel>
              </div>
            </td>
          </tr>
          <tr>
            <td width='50%'>
              <div align='center' id='vms' className='marketing-vms'>
                <Panel header="Is it secure?" align='center'>
                  <p>These days, most of the services available on internet are running on different cloud providers. Same level of security applies to sandgit. Adding VPN on top just ensures additional security measures.</p>
                </Panel>
              </div>
            </td>
            <td width='50%'>
              <div align='center' id='vms' className='marketing-vms'>
                <Panel header="Cloning the box is easy, but how to make the initial remote setup?" align='center'>
                  <p> The same way you setup the project on your computer, setup is made for the initial template. Then you'll just clone it when you need it again.</p>
                </Panel>
              </div>
            </td>
          </tr>
          <tr>
            <td width='50%'>
              <div align='center' id='vms' className='marketing-vms'>
                <Panel header="Is it a good fit for new projects?" align='center'>
                  <p>Main advantage when starting a new project is that you get for free a variety of MPVs in different and configurable stacks, including frontend (React / Angular), backend (multiple languages, multiple frameworks), databases, web authentication, user management, etc. - all of them in one template. Next one in your team will just clone it, no need for any setup.</p>
                </Panel>
              </div>
            </td>
            <td width='50%'>
              <div align='center' id='vms' className='marketing-vms'>
                <Panel header="How to setup existing projects?" align='center'>
                  <p>Pretty much the same way as new projects. There might be other services / integrations to setup, but our team will help you onboard your projects at no additional cost. Any source control like git, mercurial, svn, perforce, etc. are supported, we also have intergations with GitHub.</p>
                </Panel>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div id='contact-us' align='center'>
        <ContactUs />
      </div>
      <div className='marketing-down'>
        sandgit@2022
      </div>
    </div>
  );
}

export default V1Marketing;