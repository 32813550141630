import './CompDirectory.css';
import 'primeicons/primeicons.css';

import { PageHeader } from '../header/PageHeader';
import { Button } from 'primereact/button';

function CompDirectory() {
    const products = [
        {
            name: 'Vasile',
            email: 'vasile@gmail.com',
            status: 'active',
        },
        {
            name: 'Dana',
            email: 'dana@gmail.com',
            status: 'active',
        },
        {
            name: 'Costel',
            email: 'costel@gmail.com',
            status: 'active',
        },
    ];

    const users = products.map(
        (user) => {
            return (
                <div className='directory-user'>
                    <span className='dir-item'><i className="pi pi-user"></i></span>
                    <span className='dir-item'>{user.name}</span>
                    <span className='dir-item'>{user.email}</span>
                    <span className='dir-item'>{user.status}</span>
                    <span className='dir-item'>
                        <Button label="Suspend" className="p-button-danger" />
                    </span>
                </div>
            );
        }
    );

    return (
        <div>
            <PageHeader mode='company' />
            <div className='directory'>
                <div align='right'>
                    <Button label="Add user" className="p-button-success" />
                </div>
                {users}
            </div>
        </div>
    );
}

export default CompDirectory;