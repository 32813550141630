import axios from 'axios';
import Cookies from 'universal-cookie';

function getHeaders() {
    const cookies = new Cookies();
    return {
        'Authorization': 'Bearer ' + cookies.get('token')
    }
}

async function getMemberships() {
    return await axios.get(
        '/projectmembership/',
        {
            headers: getHeaders(),
            params: {
                usecase: 'shared',
            }
        }
    );

}

export { getHeaders, getMemberships };