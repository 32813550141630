import './V3CardWithPoint.css';

function V3CardWithPoint({ title, body }) {
    return (
        <div align='center' style={{
            display: 'flex',
            justifyContent: 'center',
        }}>
            <div align='left' className='v3-card-with-point'>
                <div className='v3-card-with-point-title'>
                    <div className='v3-card-with-point-p pink' />
                    <div>
                        <h1 className='v3-text-green'>
                            {title}
                        </h1>
                        <div>
                            {body}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default V3CardWithPoint;