import { useEffect, useState } from "react";
import { getMemberships } from "../../services/ServiceMembership.ts";
import V3Button from "../button/V3Button";
import V3ProjectMembership from "../project_membership/V3ProjectMembership";

import { Dialog } from 'primereact/dialog';
import { createMembership } from "../../services/ServiceMembership.ts";

function V3ProjectMembers({ projectID }) {
    const [email, setEmail] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [memberships, setMemberships] = useState([]);
    const loadMemberships = () => {
        getMemberships(projectID).then(
            (response) => setMemberships(response.data)
        );
    }
    useEffect(
        () => {
            loadMemberships();
        },
        [projectID],
    );

    const divMemberships = memberships.map(
        m =>
            <V3ProjectMembership
                membership={m}
                loadMemberships={loadMemberships}
            />
    );

    const addMembership = () => {
        const data = {
            project_id: projectID,
            email: email,
            status: 'test',
        };
        createMembership(data).then(
            (_response) => {
                loadMemberships();
                setShowDialog(false);
            }
        );
    }

    return (
        <div>
            <div className="v3-feed-main-item-center">
                <h2>
                    Add more people on your project.
                </h2>
                <V3Button
                    option='success'
                    label='ADD MEMBER'
                    onClick={() => setShowDialog(true)}
                />
                <Dialog
                    header='Add member'
                    visible={showDialog}
                    onHide={() => setShowDialog(false)}
                    headerStyle={{
                        backgroundImage: 'none',
                        backgroundColor: 'black',
                        color: 'white',
                        borderColor: 'red',
                        borderStyle: 'solid',
                        borderBottom: '0'
                    }}
                    contentStyle={{
                        backgroundImage: 'none',
                        backgroundColor: 'black',
                        color: 'white',
                        borderColor: 'red',
                        borderStyle: 'solid',
                        borderTop: '0'
                    }}
                >
                    <div>
                        Add a new member
                        <div style={{ marginTop: '1em' }}>
                            <input
                                className='v3-input'
                                type='text'
                                placeholder='email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div style={{ display: 'flex', marginTop: '1em' }}>
                            <V3Button
                                label='CONFIRM'
                                onClick={addMembership}
                            />
                            <V3Button
                                label='CANCEL'
                            />
                        </div>
                    </div>
                </Dialog>
            </div>
            {divMemberships}
        </div>
    );
}

export default V3ProjectMembers;