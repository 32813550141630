import './Projects.css';

import 'animate.css';
import axios from 'axios';
import { useState, useEffect } from 'react';

import img_env from './../v2/resources/env.svg';

import { PageHeader } from '../header/PageHeader';
import ProjectCover from './ProjectCover';

import { Dropdown } from 'primereact/dropdown';
import { getHeaders } from '../Services';

function Projects() {
    const headers = getHeaders();
    const [projects, setProjects] = useState([]);
    const [city, setCity] = useState({ name: 'All', code: 'all' });

    const getProjects = () => {

        axios.get(
            '/project',
            {
                headers: headers,
                params: {
                    filter: city.code
                }
            }
        ).then(
            (response) => {
                setProjects(response.data);
            }
        ).catch(
            (_error) => { }
        )
    }

    useEffect(
        () => getProjects(),
        [city]
    );

    const projectDivs = projects.map(
        project => <ProjectCover
            id={project.id}
            title={project.title}
            description={project.description}
        />
    );

    if (projects.length === 0) {
        return (
            <div>
                <PageHeader mode='account' />
                <div align='center' style={{ margin: '2em' }}>
                    Looks like you don't have any project.
                    Try exploring the menu to create one...
                </div>
            </div>
        )
    }
    const cities = [
        { name: 'All', code: 'all' },
        { name: 'Private', code: 'private' },
        { name: 'Shared with me', code: 'shared_with_me' },
    ];

    return (
        <div>
            <PageHeader mode='account' />
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div className='projects-main-left'>
                    {projectDivs}
                </div>
                <div
                    className='projects-main-right animate__animated animate__slideInRight'
                >
                    <table>
                        <tr>
                            <td>
                                <img
                                    src={img_env} width='120em'
                                    style={{ padding: '1em' }}
                                />
                            </td>
                            <td>
                                <h1> My projects ({projects.length})</h1>
                            </td>
                        </tr>
                    </table>
                    <h2>
                        Things related to a project are grouped together:
                    </h2>
                    <div style={{ marginLeft: '2em', fontSize: '1.2em' }}>
                        <div>members</div>
                        <div>access policies</div>
                        <div>templates</div>
                        <div>devservers</div>
                        <div>logs</div>
                        <div>etc.</div>
                    </div>
                    <h3>Search:</h3>
                    <div>
                        <Dropdown
                            optionLabel="name"
                            value={city}
                            options={cities}
                            onChange={(e) => setCity(e.value)}
                            placeholder="filter projects"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Projects;