import axios from "axios";
import Cookies from "universal-cookie";

function getHeaders() {
    const cookies = new Cookies();
    return {
        'Authorization': 'Bearer ' + cookies.get('token')
    }
}

function getProjectMemberships() {
    return axios.get(
        '/projectmembership/',
        {
            headers: getHeaders(),
            params: {
                usecase: 'invites',
            }
        }
    );
}

function confirmProjectMembership(project_membership_id: Number) {
    return axios.patch(
        `/projectmembership/${project_membership_id}/`,
        {
            status: 'confirmed',
        },
        {
            headers: getHeaders(),
        }
    );
}

export { getProjectMemberships, confirmProjectMembership };