import './LoginDialog.css';

import axios from 'axios';
import Cookies from 'universal-cookie';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

function LoginDialog() {
    const cookies = new Cookies();
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);

    const navigate = useNavigate();

    const login = () => {
        if (username === null || password === null) {
            return;
        }

        const data = {
            username: username,
            password: password
        }

        axios.post('/api/token/', data).then(
            response => {
                const token = response.data.access;
                cookies.set('token', token);
                navigate('/product/');
            }
        ).catch(
            _error => {
            }
        )
    }

    const loginIfEnter = (e) => {
        if (e.key !== 'Enter') {
            return;
        }
        login();
    }

    return (
        <div align='center' className='v2-login'>
            <div>
                <table>
                    <tr>
                        <td>
                            <div className='login-input'>
                                <InputText
                                    value={null}
                                    onChange={(e) => setUsername(e.target.value)}
                                    onKeyDown={loginIfEnter}
                                    placeholder="email"
                                />
                            </div>
                        </td>
                        <td>
                            <div
                                className='login-input'
                                onKeyDown={loginIfEnter}
                            >
                                <InputText
                                    type='password'
                                    value={null}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="password"
                                    autoComplete="new-password"
                                />
                            </div>
                        </td>
                        <td>
                            <div className="b1">
                                <Button label="Login" onClick={login} />
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div >
    );
}

export default LoginDialog;