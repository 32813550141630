import './TemplateMyCover.css';

import './TemplateCover.css';

import axios from 'axios';
import { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';

import { Button } from 'primereact/button';

function TemplateMyCover({ data, reloadMyTemplates }) {
    const cookies = new Cookies();
    const deleteMyTemplate = () => {
        const headers = {
            'Authorization': 'Bearer ' + cookies.get('token')
        };

        axios.delete(
            `/template/${data.id}/`,
            {
                headers: headers
            }
        ).then(
            (response) => {
                reloadMyTemplates();
            }
        )

    };

    // const divServices = data.services.map(
    //     s => <div className='template-cover-service'>
    //         {s}
    //     </div>
    // );

    return (
        <div className='template-cover'>
            <h2>
                {data.title}
            </h2>
            <table width='100%'>
                <tr>
                    {/* <td>
                        <div style={{ display: 'flex' }}>
                            {divServices}
                        </div>
                    </td> */}
                    <td>
                        <div style={{ padding: '0.1em' }} align='right'>
                            <Button
                                label='Remove'
                                className='p-button-sm'
                                onClick={deleteMyTemplate}
                            />
                        </div>
                    </td>
                </tr>
            </table>

        </div >
    );
}

export default TemplateMyCover;