import './V3Diff.css';
import V3DiffLine from './V3DiffLine';

import { useState, useEffect } from 'react';

import axios from 'axios';

function V3Diff() {
    const queryString = window.location.search;
    const diff_id = queryString.split('=')[1];
    const [lines, setLines] = useState([]);

    const loadDiff = () => {
        axios.get(
            `/diff/${diff_id}/`,
        ).then(
            (response) => {
                setLines(response.data);
            }
        )
    }

    useEffect(
        () => {
            loadDiff();
        },
        [],
    );

    const divLines = lines.map(
        line =>
            <V3DiffLine body={line} />
    );
    return (
        <div align='center' className='main-diff'>
            <div style={{ marginBottom: '1em' }}>
                hello
            </div>
            <div align='center'>
                <table
                    className='my-t'
                    cellspacing="0" cellpadding="0"
                >
                    {divLines}
                </table>
            </div>
        </div>
    );
}

export default V3Diff;