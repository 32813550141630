import './CompCreate.css';

import Cookies from 'universal-cookie';

import { useState } from 'react';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { ProgressSpinner } from 'primereact/progressspinner';

import axios from 'axios';

function CompCreate() {

    const cookies = new Cookies();

    const [checked, setChecked] = useState(false);
    const [step, setStep] = useState('wait-for-input');

    const createCompany = () => {
        setStep('creating');
        const headers = {
            'Authorization': 'Bearer ' + cookies.get('token')
        }

        axios.post(
            '/company/',
            {
                name: ''
            },
            { headers: headers }
        ).then(
            result => {
                setTimeout(
                    () => { setStep('done'); },
                    1000
                );
            }
        ).catch(
            (_error) => { }
        )
    }

    return (
        <div className="comp-create">
            {
                step == 'wait-for-input' ?
                    <div>
                        <div className='comp-create-title'>
                            <table>
                                <tr>
                                    <td>
                                        <div className='comp-create-warning-icon'>
                                            <i className="pi pi-exclamation-triangle" style={{ 'fontSize': '2em' }}></i>
                                        </div>
                                    </td>
                                    <td>
                                        Looks like you don't have a company account yet...
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className='comp-create-p'>
                            To manage all your developers, devservers, have root permissions, view security logs, enforce policies, etc. you need to create a company account.
                        </div>
                        <div className="comp-create-p">
                            <Checkbox inputId="cb1" value="New York" onChange={() => { setChecked(!checked); }} checked={checked}></Checkbox>
                            <label htmlFor="cb1" className="comp-create-p">
                                I want to extend my account to have company capabilities.
                            </label>
                        </div>
                        <div className='comp-create-button-create'>
                            <Button label="Save" className="p-button-success" disabled={!checked} onClick={createCompany} />
                        </div></div>
                    : null}
            {
                step == 'creating' ?
                    <div className='comp-create-title'>
                        <table>
                            <tr>
                                <td>
                                    <ProgressSpinner />
                                </td>
                                <td>
                                    Saving...
                                </td>
                            </tr>
                        </table>
                    </div>
                    : null
            }
            {
                step == 'done' ?
                    <div className='comp-create-title'>
                        <table>
                            <tr>
                                <td>
                                    <div style={{ color: 'green', padding: '16px' }}>
                                        <i className="pi pi-check-circle" style={{ 'fontSize': '3em' }}></i>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        Your account has company capabilities now.
                                    </div>
                                    <div>
                                        Explore the menu for more options.
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    : null
            }
        </div >
    );
}

export { CompCreate };