import './CompDetails.css';

import { useState } from 'react';
import { PageHeader } from '../header/PageHeader';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';

function CompDetails() {
    const [activeIndex, setActiveIndex] = useState(null);
    const onClick = (itemIndex) => {
        let _activeIndex = activeIndex ? [...activeIndex] : [];

        if (_activeIndex.length === 0) {
            _activeIndex.push(itemIndex);
        }
        else {
            const index = _activeIndex.indexOf(itemIndex);
            if (index === -1) {
                _activeIndex.push(itemIndex);
            }
            else {
                _activeIndex.splice(index, 1);
            }
        }

        setActiveIndex(_activeIndex);
    }

    return (
        <div>
            <PageHeader mode='company' />
            <div className="details">
                <h2>
                    Company details page
                </h2>

                <Accordion multiple activeIndex={[0]}>
                    <AccordionTab header="Invites">
                        <div>
                            <div align='center'>
                                <p>
                                    Use this link to invite coworkes to join you here.
                                </p>
                                <h4>
                                    https://rm2.westinvoices.com/signup/invite?id=dsafsa8432j32432n5329432mx
                                </h4>
                            </div>
                            <div align='center'>
                                <Button label="Renew invite link" className="p-button-success" />
                            </div>
                        </div>
                    </AccordionTab>
                    <AccordionTab header="Quotas" disabled>
                    </AccordionTab>
                </Accordion>
            </div>
        </div>
    );
}

export default CompDetails;