import './Benefit.css';

function Benefit({ img_src, title, content }) {
    let img = null;
    if (img_src != null) {
        img = <div>
            <img src={img_src} className='benefit-img'></img>
        </div>;
    }

    return (
        <div className='benefit'>
            {img}
            <div className='benefit-main'>
                <div align='center'>
                    <h2 className='benefit-h2'>
                        {title}
                    </h2>
                </div>
                <p className='benefit-p' align='justify'>
                    {content}
                </p>
            </div>
        </div>
    );
}

export { Benefit };