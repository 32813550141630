
import axios from 'axios';
import { useState } from 'react';
import { getHeaders } from '../Services';

import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';

import { PageHeader } from '../header/PageHeader';
import './Templates.css';

function Templates() {
    const [step, setStep] = useState('input');
    const [name, setName] = useState('');
    const [snapshotID, setSnapshotID] = useState('');
    const [assignSubdomain, setAssignSubdomain] = useState(false);

    const createTemplate = () => {
        axios.post(
            '/template/',
            {
                'name': name,
                'snapshot_id': snapshotID,
                'assign_subdomain': assignSubdomain,
            },
            {
                headers: getHeaders(),
            }
        ).then(
            (_response) => {
                setStep('success');
            }
        )
    };

    if (step == 'success') {
        return (
            <div>
                <PageHeader mode='account' />
                <div align='center'>
                    Template was successfully created.
                </div>
            </div>
        );
    }

    return (
        <div>
            <PageHeader mode='account' />
            <div align='center' style={{ margin: '4em' }}>
                <h2>
                    Create a template from a snapshot id
                </h2>
                <div style={{ margin: '0.5em' }}>
                    <InputText
                        placeholder="template name"
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div style={{ margin: '0.5em' }}>
                    <InputText
                        placeholder='snapshot id'
                        onChange={(e) => setSnapshotID(e.target.value)}
                    />
                </div>
                <div
                    style={{ margin: '0.5em', display: 'flex', justifyContent: 'center' }}
                >
                    <div style={{ margin: '0.5em' }}>
                        <Checkbox
                            onChange={(e) => setAssignSubdomain(e.target.value)}
                        />
                    </div>
                    <div style={{ margin: '0.5em' }}>
                        assign subdomain
                    </div>
                </div>
                <div style={{ margin: '0.5em' }}>
                    <Button
                        label='Create'
                        disabled={name === '' || snapshotID == ''}
                        onClick={createTemplate}
                    />
                </div>
            </div>
        </div>
    );
}

export default Templates;