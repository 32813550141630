import './V3ProjectMenu.css';
import { useState } from 'react';
import classNames from 'classnames';

function V3ProjectMenu({ selectedItem, setSelectedItem }) {
    const menuItems = [
        {
            id: 'summary',
            label: 'Summary',
        },
        {
            id: 'templates',
            label: 'Templates',
        },
        {
            id: 'devservers',
            label: 'Devservers',
        },
        {
            id: 'members',
            label: 'Members',
        },
        {
            id: 'settings',
            label: 'Settings',
        }
    ];

    const divMenuItems = menuItems.map(
        item =>
            <div
                className={
                    classNames(
                        {
                            'v3-project-menu-item': true,
                            'v3-project-menu-item-selected': selectedItem == item.id,
                        }
                    )
                }
                onClick={() => setSelectedItem(item.id)}
            >
                {item.label}
            </div>
    );

    return (
        <div className='v3-project-menu'>
            {divMenuItems}
        </div>
    );
}

export default V3ProjectMenu;