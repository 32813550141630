import './../feed/V3Feed.css';
import './../project_menu/V3ProjectMenu.css';


import V3ProjectInvite from './V3ProjectInvite';

function V3HomeNotifications({
    loadProjects,
    projectMemberships,
    loadProjectMemberships,
}) {
    const divNotifications = projectMemberships.map(
        pm =>
            <V3ProjectInvite
                loadProjects={loadProjects}
                projectMembership={pm}
                loadProjectMemberships={loadProjectMemberships}
            />
    );

    return (
        <div>
            {
                projectMemberships.length == 0
                    ? <div className='v3-feed-main-item-center'>
                        <h1>Notifications</h1>
                        <h3>
                            You don't have any notifications at the moment.
                        </h3>
                    </div>
                    : null
            }
            {divNotifications}
        </div >
    );
}

export default V3HomeNotifications;