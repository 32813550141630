import './ProjectDetailsWarning.css';


function ProjectDetailsWarning({ message }) {
    return (
        <div className='project-details-warning'>
            <table>
                <tr>
                    <td>
                        <i className="pi pi-exclamation-triangle"></i>
                    </td>
                    <td>
                        {message}
                    </td>
                </tr>
            </table>
        </div >
    );
}

export default ProjectDetailsWarning;