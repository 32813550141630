import { useEffect, useState } from "react";
import { getTemplateEdges } from "../../services/ServiceTemplates.ts";
import V3Button from "../button/V3Button";
import V3ProjectTemplate from "./V3ProjectTemplate";

import { Dialog } from 'primereact/dialog';

import './../feed/V3Feed.css';
import { createTemplate } from "../../services/ServiceTemplates.ts";
import { createTemplateEdge } from "../../services/ServiceTemplates.ts";

function V3ProjectTemplates({ projectID, goToDevserversPage }) {
    const [showDialog, setShowDialog] = useState(false);
    const [templateEdges, setTemplateEdges] = useState([]);
    const [templateName, setTemplateName] = useState('');
    const [templateSnapshotID, setTemplateSnapshotID] = useState('');

    const loadTemplateEdges = () => {
        getTemplateEdges(projectID).then(
            (response) => {
                setTemplateEdges(response.data);
            }
        );
    }

    useEffect(
        () => {
            loadTemplateEdges();
        },
        [projectID],
    );

    const divTemplates = templateEdges.map(
        t =>
            <V3ProjectTemplate
                key={t.id}
                projectID={projectID}
                templateEdge={t}
                loadTemplateEdges={loadTemplateEdges}
                goToDevserversPage={goToDevserversPage}
            />
    );

    const addTemplate = () => {
        const data = {
            'name': templateName,
            'snapshot_id': templateSnapshotID,
            'assign_subdomain': true, // deprecated
        };
        // TODO: reconsider this.
        createTemplate(data).then(
            (response) => {
                const template_id = response.data;
                createTemplateEdge(projectID, template_id).then(
                    (_response) => {
                        loadTemplateEdges();
                        setShowDialog(false);
                    }
                )
            }
        );
    }

    return (
        <div>
            <div className="v3-feed-main-item-center">
                <div style={{ display: 'flex' }}>
                    <V3Button
                        label='CREATE TEMPLATE'
                        option='success'
                        onClick={() => setShowDialog(true)}
                    />
                    <Dialog
                        header='Create template'
                        visible={showDialog}
                        onHide={() => setShowDialog(false)}
                        headerStyle={{
                            backgroundImage: 'none',
                            backgroundColor: 'black',
                            color: 'white',
                            borderColor: 'red',
                            borderStyle: 'solid',
                            borderBottom: '0'
                        }}
                        contentStyle={{
                            backgroundImage: 'none',
                            backgroundColor: 'black',
                            color: 'white',
                            borderColor: 'red',
                            borderStyle: 'solid',
                            borderTop: '0'
                        }}
                    >
                        <div>
                            <div style={{ marginTop: '1em' }}>
                                <input
                                    className='v3-input'
                                    placeholder='template name'
                                    type='text'
                                    value={templateName}
                                    onChange={(e) => setTemplateName(e.target.value)}
                                />
                            </div>
                            <div style={{ marginTop: '1em' }}>
                                <input
                                    className='v3-input'
                                    placeholder='snapshot id'
                                    type='text'
                                    value={templateSnapshotID}
                                    onChange={(e) => setTemplateSnapshotID(e.target.value)}
                                />
                            </div>
                            <div style={{ marginTop: '2em' }}>
                                <V3Button
                                    label='CREATE'
                                    option='success'
                                    onClick={addTemplate}
                                />
                            </div>
                        </div>
                    </Dialog>
                    <V3Button
                        label='TEMPLATES STORE'
                        option='success'
                        disabled={true}
                    />
                </div>
            </div>
            {divTemplates}
        </div>
    );
}

export default V3ProjectTemplates;