import './InternCharts.css';

import InternChartMarketingPage from './InternChartMarketingPage';
import InternChartDemoStart from './InternChartDemoStart';
import InternChartAllEvents from './InternChartAllEvents';
import InternChartLastSignups from './InternChartLastSignups';
import InternHeader from '../header/InternHeader';
import InternChartDAP from './InternChartDAP';
import InternChartMAP from './InternChartMAP';
import InternChartProjectsTotal from './InternChartProjectsTotal';

function InternCharts() {
    return (
        <div>
            <InternHeader />
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <InternChartMarketingPage />
                <InternChartDemoStart />
                <InternChartAllEvents />
                <InternChartLastSignups />
                <InternChartDAP />
                <InternChartMAP />
                <InternChartProjectsTotal />
            </div>
        </div>
    );
}

export default InternCharts;