import './ProjectDetailsAddTemplateDialog.css';

import { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';

import { Button } from 'primereact/button';
import { getHeaders } from '../Services';

function ProjectDetailsAddTemplateDialog({ projectID, close }) {
    const cookies = new Cookies();
    const [myTemplates, setMyTemplates] = useState([]);
    const navigate = useNavigate();
    const headers = getHeaders();

    // TODO: remove duplicates.
    const getMyTemplates = () => {
        axios.get(
            '/template/',
            {
                headers: headers
            }
        ).then(
            (response) => {
                setMyTemplates(response.data);
            }
        )
    };

    const addTemplateToProject = (templateID) => {
        axios.post(
            '/edgeprojecttodevtemplate/',
            {
                project_id: projectID,
                template_id: templateID,
            },
            {
                headers: headers
            }
        ).then(
            (response) => {
                close();
            }
        )
    };

    useEffect(
        () => getMyTemplates(),
        []
    );

    const divMyTemplates = myTemplates.map(
        t =>
            <div
                className='project-add-template-line'
                onClick={() => addTemplateToProject(t.id)}
            >
                <table>
                    <tr>
                        <td>
                            <i className="pi pi-server" style={{ 'fontSize': '2em' }}></i>
                        </td>
                        <td>
                            {t.title}
                        </td>
                    </tr>
                </table>
            </div>
    );

    if (myTemplates.length == 0) {
        return (
            <div align='center'>
                <h4>
                    Looks like you don't have any template on this project.
                </h4>
                <h5>
                    Explore some ready to go templates.
                </h5>
                <div>
                    <Button
                        label='Store templates'
                        onClick={() => navigate('/templates/')}
                    />
                </div>
                <h5>
                    Check your list of templates.
                </h5>
                <div>
                    <Button
                        label='My templates'
                        onClick={() => navigate('/templates/my/')}
                    />
                </div>
            </div>
        );
    }

    return (
        <div>
            <h3>
                Select template box to add on yor project
            </h3>
            <div className='project-add-template'>
                {divMyTemplates}
            </div>
        </div>
    );
}

export default ProjectDetailsAddTemplateDialog;
