import './ProjectMember.css';

import { useState, useEffect, useCallback } from 'react';
import { Dialog } from 'primereact/dialog';
import ProjectMembershipDialog from './ProjectMembershipDialog';

function ProjectMember({ projectID, data, reloadMembers }) {
    const [showMembershipDialog, setShowMembershipDialog] = useState(false);

    const invited = data.status == 'invited' ?
        <div style={{ fontSize: '0.7em' }}>
            (invited)
        </div> : null;

    const doEverything2 = useCallback(
        () => {
            setShowMembershipDialog(false);
            reloadMembers();
        },
        [reloadMembers]
    );

    return (
        <div>
            <div
                className='project-member'
                onClick={() => setShowMembershipDialog(true)}
                align='left'
            >
                <i className="pi pi-user" style={{ padding: '0.2em' }}></i>
                {data.email}
                <div align='right' style={{ color: 'greu', fontStyle: 'italic' }}>
                    {invited}
                </div>
            </div>
            <Dialog
                header='Member'
                visible={showMembershipDialog}
                onHide={() => setShowMembershipDialog(false)}
            >
                <ProjectMembershipDialog
                    projectID={projectID}
                    data={data}
                    close={doEverything2}
                />
            </Dialog>
        </div>
    );
}

export default ProjectMember;