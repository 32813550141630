
import axios from 'axios';
import { useState, useEffect } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { getHeaders } from '../Services';
import ProjectDetailsCta from './ProjectDetailsCta';

import './ProjectDetailsDescription.css';

function ProjectDetailsDescription({
    projectID,
    description
}) {
    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState(description);
    const headers = getHeaders();

    const saveDetails = () => {
        axios.patch(
            `/project/${projectID}/`,
            {
                description: value
            },
            {
                headers: headers
            }
        ).then(
            (response) => {
                setIsEditing(false);
            }
        ).catch(
            (error) => {
            }
        )
    };

    if (isEditing) {
        return (
            <div>
                <InputTextarea
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    autoResize={true}
                    style={{ backgroundColor: 'greenyellow' }}
                />
                <ProjectDetailsCta
                    message='save'
                    onClick={() => saveDetails()}
                />
            </div>
        );
    }

    return (
        <div>
            {value}
            <ProjectDetailsCta
                message='edit'
                onClick={() => setIsEditing(true)}
            />
        </div>
    );
}
export default ProjectDetailsDescription;