import V3PRojectSettingsDelete from "./V3ProjectSettingsDelete";

function V3ProjectSettings({ projectID, loadProjects }) {
    return (
        <div>
            <V3PRojectSettingsDelete
                projectID={projectID}
                loadProjects={loadProjects}
            />
        </div>
    );
}

export default V3ProjectSettings;