import './MarketingMenu.css';

import { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import LoginDialog from './LoginDialog';

function MarketinMenu({ usecase }) {
    const [showLoginDialog, setShowLoginDialog] = useState(false);

    return (
        <div className='v2-menu'>
            <table width='100%'>
                <tr>
                    <td>
                        <div
                            className='v2-menu-item v2-menu-item-left'
                            align='center'
                        >
                            {usecase == 'demo' ? 'Back home' : 'Why Sandgit'}
                        </div>
                    </td>
                    <td>
                        <div className='v2-menu-item' align='center'>
                            Features
                        </div>
                    </td>
                    <td>
                        <div className='v2-menu-item' align='center'>
                            Testimonials
                        </div>
                    </td>
                    <td>
                        <div className='v2-menu-item' align='center'>
                            Contact us
                        </div>
                    </td>
                    <td width='40%'>
                        <div
                            className='v2-menu-item v2-menu-item-right'
                            align='center'
                            onClick={() => { setShowLoginDialog(true); }}
                        >
                            Log In
                        </div>
                        <Dialog
                            header="Login"
                            visible={showLoginDialog}
                            onHide={() => setShowLoginDialog(false)}
                        >
                            <LoginDialog />
                        </Dialog>
                    </td>
                </tr>
            </table>
        </div>
    );
}

export default MarketinMenu;