import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import './ProjectProvisioning.css';
import ProjectProvisioningUpdateTargetDialog from './ProjectProvisioningUpdateTargetDialog';

import { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

function ProjectProvisioning({ project, getProject }) {
    const cookies = new Cookies();
    const headers = {
        'Authorization': 'Bearer ' + cookies.get('token')
    }
    const [showDialog, setShowDialog] = useState(false);
    const [devserversProvisional, setDevserversProvisional] = useState([]);
    const [devserversProvisioning, setDevserversProvisioning] = useState([]);

    const getDevserversProvisional = () => {
        axios.get(
            '/devserver/',
            {
                headers: headers,
                params: {
                    usecase: 'provisional',
                    project_id: project.id,
                }
            }
        ).then(
            (response) => {
                setDevserversProvisional(response.data);
            }
        )
    };

    const getDevserversProvisioning = () => {
        axios.get(
            '/devserver/',
            {
                headers: headers,
                params: {
                    usecase: 'provisioning',
                    project_id: project.id,
                }
            }
        ).then(
            (response) => {
                setDevserversProvisioning(response.data);
            }
        )
    };

    const scheduleProvisioning = () => {
        axios.post(
            '/edgeprojecttodevserver/',
            {
                project_id: project.id,
                usecase: 'provision_one_server'
            },
            {
                headers: headers
            }
        ).then(
            (_response) => {
                getDevserversProvisioning();
            }
        )
    }

    const clearProvisionalQueue = () => {
        devserversProvisional.map(
            d => {
                axios.delete(
                    `/devserver/${d.id}/`,
                    {
                        headers: headers
                    }
                ).then(
                    () => {
                        getDevserversProvisional();
                    }
                );
            }
        );
    }

    const clearProvisioningQueue = () => {
        devserversProvisioning.map(
            d => {
                axios.delete(
                    `/devserver/${d.id}/`,
                    {
                        headers: headers
                    }
                ).then(
                    () => {
                        getDevserversProvisioning();
                    }
                );
            }
        );
    }

    useEffect(
        () => getDevserversProvisional(),
        [],
    );

    useEffect(
        () => getDevserversProvisioning(),
        [],
    );

    const divDevserversProvisional = devserversProvisional.map(
        d => <div>
            Provisional Devserver {d.url}
        </div>
    )
    const divDevserversProvisioning = devserversProvisioning.map(
        d => <div>
            Provisioning {d.name}
        </div>
    )

    return (
        <div>
            <div style={{ width: '49em', display: 'flex' }} align='left'>

                <div style={{ margin: '1em' }}>
                    <div align='center'>
                        Provisioning target:
                        <h1>
                            {project.provisional_target ?? 'not set'}
                        </h1>
                    </div>
                    <div style={{ margin: '1em' }} align='center'>
                        <Button
                            className='p-button-sm'
                            label='Change'
                            onClick={() => setShowDialog(true)}
                        />
                    </div>
                    <Dialog
                        header="Update provisioning target"
                        visible={showDialog}
                        onHide={() => setShowDialog(false)}
                    >
                        <ProjectProvisioningUpdateTargetDialog
                            project={project}
                            close={() => {
                                getProject();
                                setShowDialog(false);
                            }}
                        />
                    </Dialog>
                </div>
                <div style={{ margin: '1em' }}>
                    Provisioned ({devserversProvisional.length})
                    <div style={{ margin: '1em' }}>
                        {divDevserversProvisional}
                    </div>
                    <div align='center'>
                        <Button
                            label='Clear the queue'
                            className='p-button-sm p-button-danger'
                            onClick={clearProvisionalQueue}
                            disabled={devserversProvisional.length == 0}
                        />
                    </div>
                </div>
                <div style={{ margin: '1em' }}>
                    Provisioning now ({devserversProvisioning.length})
                    <div style={{ margin: '1em' }}>
                        {divDevserversProvisioning}
                    </div>
                    <div style={{ margin: '1em', display: 'flex' }}>
                        <div style={{ margin: '0.2em' }}>
                            <Button
                                label='Schedule'
                                className='p-button-sm p-button-success'
                                onClick={scheduleProvisioning}
                            />
                        </div>
                        <div style={{ margin: '0.2em' }}>
                            <Button
                                label='Clear the queue'
                                className='p-button-sm p-button-danger'
                                onClick={clearProvisioningQueue}
                                disabled={devserversProvisioning.length == 0}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProjectProvisioning;