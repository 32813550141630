import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from 'react';
import { getDevserver } from '../../services/ServiceDevserver.ts';
import V3Button from '../button/V3Button';
import './../feed/V3Feed.css';

import { Dialog } from 'primereact/dialog';
import { deleteDevserver } from '../../services/ServiceDevserver.ts';

function V3ProjectDevserver({ devEdge, loadDevserverEdges }) {
    const [devserver, setDevserver] = useState(null);
    const [showDialog, setShowDialog] = useState(false);

    const loadDevserver = () => {
        getDevserver(devEdge.devserver_id).then(
            (response) => {
                setDevserver(response.data);
                if (response.data.status == 'setting_up') {
                    setTimeout(
                        () => loadDevserver(),
                        5000,
                    )
                }
            }
        );
    }
    useEffect(
        () => {
            loadDevserver();
        },
        [devEdge]
    )

    const rmDevserver = () => {
        deleteDevserver(devserver.id).then(
            (_response) => {
                setShowDialog(false);
                loadDevserverEdges();
            }
        );
    }

    if (devserver == null) {
        return (
            <div className="v3-feed-main-item-center">
                Loading...
            </div>
        );
    }
    if (devserver.status != 'ready' && devserver.status != 'setting_up') {
        return (
            <div></div>
        );
    }

    return (
        <div className="v3-feed-main-item-center">
            devserver
            <h2>
                {devserver.name}
            </h2>
            <div>
                <a
                    href={'https://' + devserver.url}
                    style={{ color: 'white' }}
                    target='_blank'
                >
                    {devserver.url}
                </a>
            </div>
            <div>
                edge id: &nbsp; {devEdge.id}
            </div>
            <div>
                Created by: &nbsp; {devserver.owner}
            </div>
            <div>
                ip address: &nbsp; {devserver.droplet_ipv4}
            </div>
            <div>
                origin template: &nbsp; n/a
            </div>
            {
                devserver.status == 'ready'
                    ? <div>
                        status: &nbsp; {devserver.status}
                    </div>
                    : null
            }
            {
                devserver.status == 'setting_up'
                    ? <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'left',
                            marginTop: '2em',
                        }}
                    >
                        <div>
                            <ProgressSpinner
                                style={{ height: '3em', width: '3em', marginRight: '1em' }}
                            />
                        </div>
                        <div>
                            <b>
                                setting up...
                            </b>
                        </div>
                    </div>
                    : null
            }

            <div style={{ display: 'flex', justifyContent: 'right' }}>
                <V3Button
                    label='SETTINGS'
                    disabled={devserver.status != 'ready'}
                    onClick={() => setShowDialog(true)}
                />
                <Dialog
                    header='Settings'
                    visible={showDialog}
                    onHide={() => setShowDialog(false)}
                    headerStyle={{
                        backgroundImage: 'none',
                        backgroundColor: 'black',
                        color: 'white',
                        borderColor: 'red',
                        borderStyle: 'solid',
                        borderBottom: '0'
                    }}
                    contentStyle={{
                        backgroundImage: 'none',
                        backgroundColor: 'black',
                        color: 'white',
                        borderColor: 'red',
                        borderStyle: 'solid',
                        borderTop: '0'
                    }}
                >
                    <div style={{ marginRight: '3em' }}>
                        <div>
                            <h2>
                                Delete devserver
                            </h2>
                            <div>
                                <V3Button
                                    label='Delete'
                                    option='danger'
                                    onClick={rmDevserver}
                                />
                            </div>
                        </div>
                    </div>
                </Dialog>
                <V3Button
                    label='HOW TO CONNECT'
                    disabled={devserver.status != 'ready'}
                />
            </div>
        </div>
    );
}

export default V3ProjectDevserver;