import axios from "axios";
import Cookies from "universal-cookie";

function getHeaders() {
    const cookies = new Cookies();
    return {
        'Authorization': 'Bearer ' + cookies.get('token')
    }
}

function getDevserver(devserver_id: Number) {
    return axios.get(
        `/devserver/${devserver_id}/`,
        {
            headers: getHeaders(),
        }
    );
}

function deleteDevserver(devserver_id: Number) {
    return axios.delete(
        `/devserver/${devserver_id}/`,
        {
            headers: getHeaders(),
        }
    );
}

function getDevserversProvisioning(project_id: Number) {
    return axios.get(
        '/devserver/',
        {
            headers: getHeaders(),
            params: {
                usecase: 'provisioning',
                project_id: project_id,
            }
        }
    );
}

function getDevserversProvisional(project_id: Number) {
    return axios.get(
        '/devserver/',
        {
            headers: getHeaders(),
            params: {
                usecase: 'provisional',
                project_id: project_id,
            }
        }
    );
}

/* Provisions one server. */
function scheduleDevserverProvisioning(project_id: Number) {
    return axios.post(
        '/edgeprojecttodevserver/',
        {
            project_id: project_id,
            usecase: 'provision_one_server',
        },
        {
            headers: getHeaders(),
        }
    );
}

export {
    getDevserver,
    getDevserversProvisioning,
    getDevserversProvisional,
    scheduleDevserverProvisioning,
    deleteDevserver,
};