import InternHeader from './header/InternHeader';
import './Intern.css';

import InternObject from './InternObject';

function Intern() {

    return (
        <div>
            <InternHeader />
            <div className='intern-objects'>
                <InternObject
                    title='Charts'
                    description='Overview about how product is doing in real time.'
                    link='/intern/charts'
                />
                <InternObject
                    title='Jobs'
                    description='All running or pending jobs.'
                    link='/intern/jobs'
                />
                <InternObject
                    title='Gate Keepers'
                    description='Controls when and how to rollout features.'
                    link=''
                />
                <InternObject
                    title='Queries'
                    description='Can perform manual queries on the database.'
                    link=''
                />
            </div>
        </div>
    );
}

export default Intern;