import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Marketing from './v2/Marketing';
import reportWebVitals from './reportWebVitals';

import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";

import axios from 'axios';

const { REACT_APP_API_ENDPOINT } = process.env;

axios.defaults.baseURL = REACT_APP_API_ENDPOINT;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);