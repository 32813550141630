import axios from "axios";
import Cookies from "universal-cookie";

function getHeaders() {
    const cookies = new Cookies();
    return {
        'Authorization': 'Bearer ' + cookies.get('token')
    }
}

function getTemplateEdges(projectID: Number) {
    return axios.get(
        '/edgeprojecttodevtemplate/',
        {
            headers: getHeaders(),
            params: {
                project_id: projectID
            },
        }
    );
}

function getTemplate(template_id: Number) {
    return axios.get(
        `/template/${template_id}/`,
        {
            headers: getHeaders(),
        }
    );
}

function createTemplate(data: any) {
    return axios.post(
        '/template/',
        data,
        {
            headers: getHeaders(),
        }
    );
}

function createTemplateEdge(
    project_id: Number,
    template_id: Number,
) {
    return axios.post(
        '/edgeprojecttodevtemplate/',
        {
            project_id: project_id,
            template_id: template_id,
        },
        {
            headers: getHeaders(),
        }
    );
}

function deleteTemplateEdge(template_edge_id: Number) {
    return axios.delete(
        `/edgeprojecttodevtemplate/${template_edge_id}/`,
        {
            headers: getHeaders(),
        }
    );
}

export {
    getTemplateEdges,
    getTemplate,
    createTemplate,
    createTemplateEdge,
    deleteTemplateEdge,
};