import axios from 'axios';
import { useState, useEffect } from 'react';
import { getHeaders } from '../Services';

import { PageHeader } from '../header/PageHeader';
import ProjectInviteCover from './ProjectInviteCover';
import img_badactors from './../v2/resources/badactors.svg';

import './ProjectInvites.css';


function ProjectInvites() {
    const headers = getHeaders();

    const [projectInvites, setProjectInvites] = useState([]);

    const getProjectInvites = () => {
        axios.get(
            '/projectmembership/',
            {
                headers: headers,
                params: {
                    usecase: 'invites',
                }
            }
        ).then(
            (response) => {
                setProjectInvites(response.data);
                setTimeout(
                    getProjectInvites(),
                    5000,
                );
            }
        );
    }
    useEffect(
        () => getProjectInvites(),
        [],
    );

    const divProjectInvites = projectInvites.map(
        p =>
            <ProjectInviteCover
                data={p}
                reloadData={getProjectInvites}
            />
    )

    if (projectInvites.length == 0) {
        return (
            <div>
                <PageHeader mode='account' />
                <div align='center' style={{ padding: '3em' }}>
                    <table>
                        <tr>
                            <td>
                                <div style={{ padding: '1em' }}>
                                    <img src={img_badactors} width='256'></img>
                                </div>
                            </td>
                            <td>
                                <h2>
                                    You don't have any invite at the moment.
                                </h2>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        );
    }

    return (
        <div>
            <PageHeader mode='account' />
            {divProjectInvites}
        </div>
    );
}

export default ProjectInvites;