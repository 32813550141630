import './PageMyDevservers.css';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Devserver } from './Devserver';

import 'animate.css';
import img_confused from './../../v2/resources/confused.webp';

import Cookies from 'universal-cookie';

function PageMyDevservers() {

    const cookies = new Cookies();
    const headers = {
        'Authorization': 'Bearer ' + cookies.get('token')
    }

    const [devservers, setDevservers] = useState([])

    const getDevservers = () => {
        axios.get(
            '/devserver/',
            {
                headers: headers
            }
        ).then(
            response => {
                setDevservers(response.data);
            }
        ).catch(
            _error => {

            }
        )
    };

    useEffect(
        () => getDevservers(),
        []
    );

    const devserversRendered = devservers.map(
        (d) => {
            return (
                <div className='dev-container'>
                    <Devserver
                        data={d}
                        reloadDevservers={getDevservers}
                    />
                </div>
            );
        }
    );

    return (
        <div className='devservers-main'>
            <div className='devs-left'>
                {devservers.length > 0 ?
                    devserversRendered
                    : (
                        <div className='devservers-empty-state'>
                            <table>
                                <tr>
                                    <td>
                                        <div style={{ padding: '1em' }}>
                                            <img src={img_confused} width='128'></img>
                                        </div>
                                    </td>
                                    <td>
                                        <p>
                                            Looks like you don't have any devserver at the moment.
                                        </p>
                                        <p>
                                            <b>Try exploring the Menu to create one.</b>
                                        </p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    )
                }
            </div>
            <div className='devs-right animate__animated animate__slideInRight'>
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center'
                }}>
                    <i
                        className="pi pi-server"
                        style={{ fontSize: '4em', padding: '0.2em' }}
                    />
                    <h2>
                        My devservers ({devservers.length})
                    </h2>
                </div>
                <div>
                    <h3>
                        Servers with all the development
                    </h3>
                    <h4>
                        environment setup ready for coding.
                    </h4>
                </div>
            </div>
        </div>
    );
}

export { PageMyDevservers }