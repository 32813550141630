import './V3DiffLine.css'
import classNames from 'classnames';

function V3DiffLine({ body }) {
    const isAdd = body.startsWith('+');
    const isRm = body.startsWith('-');

    if (!isAdd && !isRm) {
        body =
            <div>
                <pre>
                    <span>&nbsp;&nbsp;</span>
                    <span>&nbsp;&nbsp;</span>
                    {body}
                </pre>
            </div>;
    }
    return (
        <tr className='my-tr' border='0' align='center'>
            <td
                border='0'
                align='left'
                className={
                    classNames(
                        {
                            'my-td': true,
                            'line-red': isRm,
                        }
                    )
                }
            >

                <div className='my-cell'>
                    {isAdd ? null : <pre>{body}</pre>}
                </div>
            </td>
            <td
                align='left'
                width='10em'
                className={
                    classNames(
                        {
                            'my-td': true,
                            'line-green': isAdd,
                        }
                    )
                }
            >
                <div className='my-cell'>
                    {isRm ? null : <pre>{body}</pre>}
                </div>
            </td>
        </tr>
    );
}

export default V3DiffLine;