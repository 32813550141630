import LoginDialog from "../../v2/LoginDialog";
import V3Menu from "../menu/V3Menu";

function V3Login() {
    return (
        <div>
            <div className='v3-bg'>
                <V3Menu />
                <LoginDialog />
            </div>
        </div>
    );
}

export default V3Login;