import V3Button from "../button/V3Button";

function V3ProjectSummary({ project }) {
    return (
        <div>
            <h2>
                {project.title}
            </h2>
            <div>
                ID: &nbsp;{project.id}
            </div>
            <div>
                {project.description}
            </div>
            <V3Button label="EDIT" />
        </div>
    );
}

export default V3ProjectSummary;