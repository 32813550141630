import './V3Card.css';
import 'animate.css';
import classNames from 'classnames';

function V3Card({ title, imgSrc, duration, color }) {
    return (
        <div
            className={
                classNames(
                    {
                        'v3-card': true,
                        'animate__animated': true,
                        'animate__bounceInRight': true,
                    }
                )
            }
            style={{
                animationDuration: duration,
                backgroundImage: `url(${imgSrc})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}
        >
            <div
                className={
                    classNames(
                        {
                            'v3-card-c': true,
                            'v3-card-c-pink': color === 'pink',
                            'v3-card-c-blue': color === 'blue',
                        }
                    )
                }
            >
                {title}
            </div>
        </div>
    );
}

export default V3Card;