import './InternJobs.css';

import InternHeader from '../header/InternHeader';

import axios from 'axios';
import { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import InternJob from './InternJob';

function InternJobs() {
    const cookies = new Cookies();
    const headers = {
        'Authorization': 'Bearer ' + cookies.get('token')
    }

    const [devservers, setDevservers] = useState([]);

    const getDevserversProvisioning = () => {
        axios.get(
            '/devserver/',
            {
                headers: headers,
                params: {
                    usecase: 'intern_jobs',
                }
            }
        ).then(
            (response) => {
                setDevservers(response.data);
                setTimeout(
                    () => getDevserversProvisioning(),
                    5000,
                )
            }
        )
    };
    useEffect(
        () => getDevserversProvisioning(),
        []
    );

    const divDevservers = devservers.map(
        d => <InternJob devserver={d} />
    )

    return (
        <div>
            <InternHeader />
            <div
                style={{ display: 'flex', flexWrap: 'wrap' }}
            >
                <div style={{ margin: '2em' }}>
                    <h1>Pending jobs ({devservers.length})</h1>
                    {divDevservers}
                </div>
            </div>
        </div>
    );
}

export default InternJobs;