import classNames from 'classnames';
import './V3LeftMenu.css';

import { useNavigate } from 'react-router-dom';

function V3LeftMenu({
    projects,
    selectedItem,
    onClickItem,
    notificationsCount,
}) {
    const navigate = useNavigate();

    let divProjects = 'Loading';
    if (projects != null) {
        divProjects = projects.map(
            p =>
                <div
                    className={
                        classNames(
                            {
                                'v3-left-menu-item': true,
                                'v3-left-menu-item-selected': selectedItem == p.id,
                            }
                        )
                    }
                    onClick={
                        () => {
                            onClickItem(
                                {
                                    type: 'project',
                                    value: p.id,
                                }
                            );
                        }
                    }
                >
                    {p.title}
                </div>
        );
    }

    return (
        <div className="v3-left-menu">
            <div
                style={{ marginBottom: '3em' }}
                className={
                    classNames(
                        {
                            'v3-left-menu-item': true,
                            'v3-left-menu-item-selected': selectedItem == 'home',
                        }
                    )
                }
                onClick={
                    () => {
                        onClickItem(
                            {
                                type: 'menu',
                                value: 'home',
                            }
                        );
                    }
                }
            >
                {
                    notificationsCount > 0
                        ? 'Home (' + notificationsCount + ')'
                        : 'Home'
                }
            </div>
            {divProjects}
            <div
                style={{ marginTop: '3em' }}
                className={
                    classNames(
                        {
                            'v3-left-menu-item': true,
                            'v3-left-menu-item-selected': selectedItem == 'account',
                        }
                    )
                }
                onClick={
                    () => {
                        onClickItem(
                            {
                                type: 'menu',
                                value: 'account',
                            }
                        );
                    }
                }
            >
                My Account
            </div>
            <div
                className={
                    classNames(
                        {
                            'v3-left-menu-item': true,
                            'v3-left-menu-item-selected': selectedItem == 'logout',
                        }
                    )
                }
                onClick={
                    () => {
                        onClickItem(
                            {
                                type: 'menu',
                                value: 'logout'
                            }
                        );
                    }
                }
            >
                Logout
            </div>
        </div>
    );
}

export default V3LeftMenu;