import './InternHeader.css';

import { useNavigate } from 'react-router-dom';

function InternHeader() {
    const navigate = useNavigate();

    return (
        <div
            align='center'
            className='intern-header'
            onClick={() => navigate('/intern')}
        >
            <h1>Sandig Intern</h1>
        </div>
    );
}

export default InternHeader;