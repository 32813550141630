import { Button } from 'primereact/button';
import './ProjectProvisioningUpdateTargetDialog.css';

import { useState } from 'react';
import axios from 'axios';
import { InputText } from 'primereact/inputtext';
import { getHeaders } from '../../Services';

function ProjectProvisioningUpdateTargetDialog({ project, close }) {
    const headers = getHeaders();
    const [provisioningTarget, setProvisioningTarget] = useState(project.provisional_target ?? 0);

    const updateProvisioningTarget = () => {
        axios.patch(
            `/project/${project.id}/`,
            {
                provisional_target: provisioningTarget
            },
            {
                headers: headers
            }
        ).then(
            (_response) => {
                close();
            }
        )
    }

    return (
        <div>
            <div style={{ margin: '1em' }}>
                Provisioning target represents the total number of devservers to be pre-provisioned.
            </div>
            <div style={{ margin: '1em' }} align='center'>
                <InputText
                    value={provisioningTarget}
                    placeholder='eg.: 5'
                    onChange={(e) => setProvisioningTarget(e.target.value)}
                />
            </div>
            <div align='center'>
                <Button
                    className='p-button-sm'
                    label='Update'
                    onClick={updateProvisioningTarget}
                />
            </div>
        </div>
    );
}

export default ProjectProvisioningUpdateTargetDialog;